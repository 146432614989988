import { TariffPlanType } from '@roolz/types/api/billing'
import { CompanyRoles } from '@roolz/types/api/companies'
import { makeAutoObservable } from 'mobx'
import { Country, UserCountry } from '@roolz/types/custom'
import { billingStore } from '@/store/billing/billing.store'
import { companiesStore } from '@/store/companies/companies.store'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { getNow } from '@/utils/date'

class SystemStore {
  private _clientServerDateDiffMs: number

  online = navigator.onLine
  isLoggingOut = false
  tabVisibility = document.visibilityState

  isJivositeLoaded = false
  isAppealsModalControllerEnabled = true

  serverTime10secUpdate: number

  userCountry: UserCountry
  countries: Country[]

  constructor() {
    makeAutoObservable(this)
  }

  get clientServerDateDiffMs() {
    return this._clientServerDateDiffMs
  }

  set clientServerDateDiffMs(value: number) {
    this._clientServerDateDiffMs = value
    this.serverTime10secUpdate = getNow().getTime()
  }

  setCountries(countries: Country[]): void {
    countries.sort((a: Country, b: Country) => a.name.localeCompare(b.name))

    this.countries = countries
  }

  setUserCountry(country: UserCountry): void {
    this.userCountry = country
  }

  get canViewBillingPage(): boolean {
    const activeCompany = companiesStore.activeCompany

    if(activeCompany) {
      return !!activeCompany?.my_role && ![
        CompanyRoles.admin,
        CompanyRoles.owner,
      ].includes(activeCompany.my_role)
    }

    return !myCompaniesStore.companyIds.length
  }
}

const systemStore = new SystemStore()

setInterval(() => {
  systemStore.serverTime10secUpdate = getNow().getTime()
}, 10 * 1000)

// window.addEventListener('online', () => systemStore.online = navigator.onLine)
// window.addEventListener('offline', () => systemStore.online = navigator.onLine)

export {
  SystemStore,
  systemStore,
}
