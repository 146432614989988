import { BillingExpireState, TariffPlanType } from '@roolz/types/api/billing'
import { CompanyRoles } from '@roolz/types/api/companies'
import { observer } from 'mobx-react-lite'
import { useChangeTariff } from '@/components/company-admin/pricing/hooks/useChangeTariff'
import { ChangeTariffProcess } from '@/components/company-admin/pricing/processes/ChangeTariffProcess'
import { PremiumAppealButton } from '@/components/company-admin/pricing/ui/PremiumAppealButton/PremiumAppealButton'
import styles from '@/components/layouts/HomeLayout/Header/Header.module.scss'
import {
  PremiumPlanPaymentCountdown,
} from '@/components/ui/pricing/PremiumPlanPaymentCountdown/PremiumPlanPaymentCountdown'
import { billingStore } from '@/store/billing/billing.store'
import { companiesStore } from '@/store/companies/companies.store'
import { myCompaniesStore } from '@/store/companies/my_companies.store'
import { profilesStore } from '@/store/profiles/profiles.store'

export const BillingBlock = observer(() => {
  if(!profilesStore.isMyProfileFilled) {
    return null
  }
  const { changePlan } = useChangeTariff()

  const billing = billingStore.billing

  const activeCompany = companiesStore.activeCompany
  const activeCompanyRole = activeCompany?.my_role

  const canManageActiveCompany = activeCompany && activeCompanyRole
    && [CompanyRoles.admin, CompanyRoles.owner].includes(activeCompanyRole)

  const myCompaniesLength = myCompaniesStore.companies.length

  if(activeCompany) {
    if(!canManageActiveCompany) {
      return null
    }

    // if(billing?.tariff_plan?.type !== TariffPlanType.Free) {
    if(billing && billing?.billing_expire_state !== BillingExpireState.Active) {
      return (
        <>
          <PremiumPlanPaymentCountdown billing={billing}/>

          <div className={styles.spacer}/>
        </>
      )
    }

    if(billing?.tariff_plan?.type !== TariffPlanType.Free) {
      return null
    }
    // }
  } else if(myCompaniesLength) {
    return null
  }

  return (
    <PremiumAppealButton
      className={styles.premiumButton}
      onClick={() => changePlan()}
    />
  )
})
