import { useCountries } from '@/hooks/useCountries'
import { getFlagUrl } from '@/utils/cdns'
import { Autocomplete, AutocompleteRenderInputParams, Box, InputAdornment, TextField } from '@mui/material'
import { Backspace } from '@roolz/icons/Backspace'
import { ExpandButton } from '@roolz/sdk/components/ui/buttons/ExpandButton/ExpandButton'
import { Country } from '@roolz/types/custom'
import { observer } from 'mobx-react-lite'
import { forwardRef, SyntheticEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './CountrySelect.module.scss'

interface Props {
  value: Country | null
  onChange: (country: Country | null) => void

  error?: boolean
  helperText?: string
  allowEmpty?: boolean
  placeholder?: string

  [key: string]: any
}

type Item = {
  name: string
  alpha2: string | null
}

export const CountrySelect = observer(forwardRef(function CountrySelect({
  onChange,
  value,

  error,
  helperText,
  allowEmpty = false,
  placeholder = '',

  ...rest
}: Props, ref: any) {
  const { t } = useTranslation('ui')

  const [open, setOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')

  const { countries } = useCountries()

  function handleChange(e: SyntheticEvent, country: Item) {
    if(country?.alpha2 === null) {
      onChange(null)
      setSearch('')
    } else {
      onChange(countries.find(({ alpha2 }) => alpha2 === country.alpha2) as Country)
    }
  }

  const autocompleteComparator = (a: Item, b: Item): boolean => {
    return a?.alpha2 === b?.alpha2
  }

  const options = useMemo((): Item[] => {
    const result = countries.map(item => ({
      name: item.name,
      alpha2: item.alpha2
    }))

    if(allowEmpty) {
      result.unshift({
        name: t('autocomplete.dont_specify'),
        alpha2: null as any
      })
    }

    return result
  }, [countries, allowEmpty])

  function handleClear() {
    setSearch('')

    if(typeof onChange === 'function') {
      onChange(null as any)
    }
  }

  return (
    <Autocomplete
      classes={{endAdornment: styles.popupIcon, popupIndicator: styles.popupIndicator}}
      inputValue={search}
      options={options}
      onInputChange={(e, newValue) => setSearch(newValue)}
      autoHighlight
      disableClearable
      getOptionLabel={(option: Item) => option?.name ?? ''}
      onChange={handleChange}
      loadingText={<LoadingText/>}
      noOptionsText={<NoOptionsText/>}
      openOnFocus={true}
      value={value as any}
      selectOnFocus={false}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      renderOption={(props: any, item: Item) => (
        <CountryItem
          item={item}
          {...props}
        />
      )}
      isOptionEqualToValue={autocompleteComparator}
      popupIcon={<ExpandButton expanded={open}/>}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return (
          <TextField
            ref={ref}
            {...params}
            error={error ?? false}
            helperText={helperText ?? ''}
            placeholder={placeholder}
            variant='outlined'
            // value={selectedCountry?.name ?? ''}
            InputProps={{
              ...params.InputProps,
              endAdornment: search && open ? (
                <InputAdornment
                  position='end'
                  onClick={handleClear}
                  className={styles.iconClear}
                >
                  <Backspace/>
                </InputAdornment>
              ) : params.InputProps.endAdornment,
              startAdornment: value?.alpha2 && (
                <InputAdornment
                  position='start'
                  sx={{
                    left: 7,
                    position: 'relative'
                  }}
                >
                  <img
                    loading='lazy'
                    width='20'
                    src={getFlagUrl(value.alpha2)}
                    alt=''
                    className={styles.inputIcon}
                  />
                </InputAdornment>
              )
            }}
          />
        )
      }}
      {...rest}
    />
  )
}))

const CountryItem = ({ item, ...rest }: {
  item: Item

  [key: string]: any
}) => {
  return (
    <Box
      component='li'
      sx={{ background: 'white' }}
      height='42px !important'
      {...rest}
    >
      <div className={styles.option}>
        <div className={styles.optionName}>
          {item?.alpha2 && (
            <img
              loading='lazy'
              width='20'
              src={getFlagUrl(item.alpha2)}
              srcSet={getFlagUrl(item.alpha2) + ' 2x'}
              alt=''
              className={styles.optionIcon}
            />
          )}
          {item.name}
        </div>
      </div>
    </Box>
  )
}

const NoOptionsText = () => {
  const { t: uiT } = useTranslation('ui')
  return <>{uiT('autocomplete.noOptions')}</>
}
const LoadingText = () => {
  const { t: uiT } = useTranslation('ui')
  return <>{uiT('autocomplete.loading')}</>
}
