import { setIn } from 'final-form'
import { FieldMetaState } from 'react-final-form'

export const yupValidateFormValues = (schema: any) => async (values: any) => {
  if(typeof schema === 'function') {
    schema = schema()
  }
  try {
    await schema.validateSync(values, { abortEarly: false })
  } catch(err) {
    return err.inner.reduce((formError: any, innerError: any) => setIn(formError, innerError.path, innerError.message), {})
  }
}

export const getFieldError = (meta: FieldMetaState<any>) => {
  if(meta.pristine) return
  if(!meta.touched) return
  if(!meta.dirty) return

  return meta.error || meta.submitError
}

export const getSubmitFieldError = (meta: FieldMetaState<any>) => {
  if(meta.submitFailed && !meta.modifiedSinceLastSubmit) {
    return getFieldError(meta)
  }

  // if(!meta.submitFailed && !meta.modifiedSinceLastSubmit) return
  //
  // return getFieldError(meta)
}
