import Collapse from '@mui/material/Collapse'
import { PaymentPeriods, TariffPlan, TariffPlanType } from '@roolz/types/api/billing'
import { memo, useRef, useState } from 'react'
import cn from 'classnames'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import ArrowUpwardIcon from '@material-symbols/svg-400/rounded/chevron_left.svg'
import { PlansScope } from '@roolz/sdk/components/pricing/blocks/PlansScope/PlansScope'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import { useElementSize } from '@roolz/sdk/hooks/useElementSize'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import styles from './Scope.module.scss'

interface Props {
  hideRegister?: boolean
  tariffs: TariffPlan[]
  onConnect?: (tariff: TariffPlan, period: PaymentPeriods) => void

  currentPlanType?: TariffPlanType
  currentPlanPeriod?: PaymentPeriods
  initialPaymentPeriod?: PaymentPeriods
}

export const Scope = memo(({
  hideRegister = false,
  tariffs,
  onConnect,

  currentPlanType,
  currentPlanPeriod,
  initialPaymentPeriod,
}: Props) => {
  const { t } = useSdkTranslation('pricing')

  const ref = useRef<HTMLDivElement | null>(null)

  const { width } = useElementSize(ref)
  const isMobile = width <= 1000

  const [visible, setVisible] = useState(false)

  return (
    <div
      className={styles.root}
      ref={ref}
    >
      <Collapse
        in={visible}
        collapsedSize={isMobile ? 140 : 200}
        timeout={1000}
      >
        <div className={cn(styles.scopeContainer, {
          [styles.scopeContainerCollapsed]: !visible,
        })}
        >
          <PlansScope
            hideRegister={hideRegister}
            isMobile={isMobile}
            tariffs={tariffs}
            onConnect={onConnect}

            currentPlanType={currentPlanType}
            currentPlanPeriod={currentPlanPeriod}
            initialPaymentPeriod={initialPaymentPeriod}
          />
        </div>
      </Collapse>

      <div className={styles.actions}>
        <Button
          onClick={() => setVisible(!visible)}
          className={styles.expand}
        >
          {t(visible ? 'scope.collapse_table' : 'scope.expand_table')}

          <MaterialSymbolIcon
            size={16}
            icon={<ArrowUpwardIcon/>}
            style={{
              transform: visible ? 'rotate(90deg)' : 'rotate(-90deg)',
              marginLeft: 2,
            }}
          />
        </Button>
      </div>
    </div>
  )
})
