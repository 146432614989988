import { TariffPlanType } from '@roolz/types/api/billing'
import cn from 'classnames'
import { memo } from 'react'
import { getPlanActionText, getPlanVariant } from '@roolz/sdk/components/pricing/utils/plans'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import styles from './PlanActionButton.module.scss'

interface Props {
  planType: TariffPlanType

  className?: string
  isSecondary?: boolean
  isCurrent?: boolean

  hideRegister?: boolean

  onClick?: () => void
}

export const PlanActionButton = memo(({
  planType,
  className,
  isSecondary = false,
  isCurrent = false,

  hideRegister = false,

  onClick,
}: Props) => {
  const { t } = useSdkTranslation('pricing')

  const variant = getPlanVariant(planType)

  const getPrimaryStyle = () => {
    if(isCurrent) return styles.current
    if(variant === 'most_popular') return styles.popular
    if(variant === 'most_valuable') return styles.valuable

    if(planType === 'free') return styles.buttonWhite
    if(planType === 'enterprise') return styles.transparent
  }

  const getSecondaryStyle = () => {
    if(isCurrent) return styles.current
    if(variant === 'most_popular') return styles.popular
    if(variant === 'most_valuable') return styles.valuable

    if(planType === 'free') return styles.white
    if(planType === 'beginner') return styles.light
  }

  return (
    <button
      className={cn(
        styles.button,
        className,
        isSecondary ? getSecondaryStyle() : getPrimaryStyle(),
        {
          [styles.buttonHidden]: hideRegister && planType === 'free' && !isCurrent,
        },
      )}
      onClick={!isCurrent ? onClick : undefined}
    >
      {isCurrent
        ? t('plan_actions.current_plan')
        : t(getPlanActionText(planType))}
    </button>
  )
})
