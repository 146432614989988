import useResizeObserver from '@react-hook/resize-observer'
import { debounce, throttle } from 'lodash-es'
import { useCallback, useLayoutEffect, useState } from 'react'

interface Size {
  width: number
  height: number
}

export const useElementSize = (ref: any, debounceMS = 100) => {
  const [size, setSize] = useState<Size>({ width: 0, height: 0 })

  const setSizeDebounced = useCallback(throttle((size) => {
      setSize({ width: size?.width, height: size?.height })
    },
    debounceMS,
    { leading: false }
  ), [])

  // DON`T TOUCH THIS.
  // When we removed it because of illusionary unnecessity, faced with visible ui restructuring (in offerRow->assignees)
  useLayoutEffect(() => {
    if(!ref.current) {
      return
    }
    const size = ref.current.getBoundingClientRect()

    setSize({ width: size?.width, height: size?.height })
  }, [])

  useResizeObserver(ref, (entry: any) => setSizeDebounced(entry.contentRect))

  return size
}
