// import * as Cookies from '@roolz/sdk/utils/cookie'
import { setGlobalCssVariable } from '@roolz/sdk/utils/css'
import { autorun, makeAutoObservable } from 'mobx'
import stylesConfig from '@/assets/scss/config.module.scss'
import { persistStore } from '@/utils/mobx'

class UiStore {
  isSidebarCollapsed = false
  isSidebarMobileOpen = false

  closeAllGlobalModals?: () => void

  constructor() {
    makeAutoObservable(this)
  }

  toggleSidebarMobileOpen(): void {
    this.isSidebarMobileOpen = !this.isSidebarMobileOpen
  }

  toggleSidebarCollapse() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed
  }
}

const uiStore = persistStore(new UiStore(), 'ui')

autorun(() => {
  if(stylesConfig?.sidebarCollapsedWidth) {
    setGlobalCssVariable('--sidebar-width', uiStore.isSidebarCollapsed
      ? stylesConfig?.sidebarCollapsedWidth
      : stylesConfig?.sidebarWidth)
  }
})

export {
  UiStore,
  uiStore,
}
