export const RoundedTriangle = () => {
  return (
    <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.29492 11.3066C1.96289 11.3066 1.67773 11.2363 1.43945 11.0957C1.20117 10.9551 1.01758 10.7637 0.888672 10.5215C0.759766 10.2832 0.695312 10.0156 0.695312 9.71875C0.695312 9.43359 0.771484 9.16016 0.923828 8.89844L5.4707 0.976562C5.61914 0.710938 5.81641 0.511719 6.0625 0.378906C6.30859 0.242188 6.56641 0.173828 6.83594 0.173828C7.10547 0.173828 7.36133 0.242188 7.60352 0.378906C7.84961 0.511719 8.04883 0.710938 8.20117 0.976562L12.748 8.89844C12.8223 9.02734 12.8789 9.16211 12.918 9.30273C12.957 9.44336 12.9766 9.58203 12.9766 9.71875C12.9766 10.0156 12.9121 10.2832 12.7832 10.5215C12.6543 10.7637 12.4707 10.9551 12.2324 11.0957C11.9941 11.2363 11.709 11.3066 11.377 11.3066H2.29492Z"
        fill="#4778EE"
      />
    </svg>
  )
}
