import { useSdkContext } from '@roolz/sdk/SdkContext'
import { TransProps } from '@roolz/sdk/types'

export const SdkTrans = ({
  components,
  ...rest
}: TransProps) => {
  const { Trans } = useSdkContext()

  const transComponents = {
    br: <br/>,
    ul: <ul/>,
    li: <li/>,
    p: <p/>,
    span: <span/>,
    hr: <hr/>,
    b: <b/>,
    strong: <strong/>,
    i: <i/>,
    small: <small/>,
    highlight: <span style={{ color: '#4778EE' }}/>,
  }

  return (
    <Trans
      components={{
        ...transComponents,
        ...components
      }}
      {...rest}
    />
  )
}
