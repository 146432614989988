export enum OS {
  Windows = 'windows',
  Ios = 'ios',
  Android = 'android',
  MacOS = 'macos',
  Linux = 'unix',
}

export interface Device {
  installation_id: string,
  phone_model: string,
  system_version: string,
  app_version: string,
  os_type: OS,
}

export enum DeviceType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export enum LoggableSocialNetwork {
  Facebook = 'facebook',
  Google = 'google',
  Apple = 'apple',
}
