import { memo } from 'react'
import { universalRoolzStaticUrl } from '@roolz/sdk/utils/urls'
import { SdkTrans } from '@roolz/sdk/components/utils/SdkTrans'
import styles from './Intro.module.scss'

const components = {
  strong: <strong/>,
  b: <b/>,
  i: <i/>,
  br: <br/>,
}

export const Intro = memo(() => (
  <div className={styles.root}>
    <h1 className={styles.title}>
      <SdkTrans
        i18nKey='pricing:intro.title'
        components={components}
      />
    </h1>

    <div className={styles.subtitle}>
      <SdkTrans
        i18nKey='pricing:intro.subtitle'
        components={components}
      />
    </div>

    <div className={styles.chip}>
      <SdkTrans
        i18nKey='pricing:intro.chip'
        components={components}
      />

      <img
        src={`${universalRoolzStaticUrl}/images/pricing/crown.png`}
        alt=''
        className={styles.chip__image}
      />
    </div>
  </div>
))
