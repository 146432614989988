import gsap from '@roolz/sdk/plugins/gsap'
import { useUpdateEffect } from 'ahooks'
import cn from 'classnames'
import { memo, ReactNode, useLayoutEffect, useRef } from 'react'
import styles from './SelectType.module.scss'

interface Item {
  value: any
  title: ReactNode
}

interface Props {
  value: Item['value']
  items: Item[]

  onChange: (item: Item['value']) => void

  className?: string
  itemClassName?: string
  activeItemClassName?: string
  activeBgColor?: string
}

export const SelectType = memo(({
  value,
  items,

  onChange,

  className,
  itemClassName,
  activeItemClassName,
  activeBgColor = 'white'
}: Props) => {
  const rootRef = useRef<any>()
  const itemRefs = useRef<(HTMLButtonElement | null)[]>([])
  const activeItemRef = useRef<any>()
  const bubbleRef = useRef<any>()

  const getActiveItemBounds = () => {
    const activeEl = activeItemRef.current

    return {
      top: activeEl?.offsetTop + 'px',
      // bottom: activeEl.offsetTop + activeEl.clientHeight + 'px',
      left: activeEl?.offsetLeft + 'px',
      width: activeEl?.clientWidth + 'px'
    }
  }

  useUpdateEffect(() => {
    const { left, width } = getActiveItemBounds()

    const tl = gsap.timeline()
    if(value === items[1].value) {
      tl.to(bubbleRef.current, {
        duration: 0.3,

        width: bubbleRef.current.clientWidth + Number.parseInt(width)
      }).to(bubbleRef.current, {
        duration: 0.3,

        left,
        width: Number.parseInt(width)
      }, '>-=60%')
    } else {
      tl.to(bubbleRef.current, {
        duration: 0.3,

        left,
        width: bubbleRef.current.clientWidth + Number.parseInt(width)
      }).to(bubbleRef.current, {
        duration: 0.3,

        width
      }, '>-=60%')
    }
  }, [value])

  useLayoutEffect(() => {
    const { top, left, width } = getActiveItemBounds()

    bubbleRef.current.style.top = top
    bubbleRef.current.style.left = left

    bubbleRef.current.style.width = width

    const observer = new ResizeObserver(() => {
      const { top, left, width } = getActiveItemBounds()

      bubbleRef.current.style.top = top
      bubbleRef.current.style.left = left

      bubbleRef.current.style.width = width
    })

    observer.observe(rootRef.current)

    return () => observer.unobserve(rootRef.current)
  }, [])

  return (
    <div className={cn(styles.root, className)} ref={rootRef}>
      {items.map(item => (
        <button
          type="button"
          ref={el => {
            itemRefs.current[item.value] = el
            if(item.value === value) activeItemRef.current = el
          }}
          className={cn(styles.item, itemClassName,
            item.value === value && cn(activeItemClassName, styles.itemActive)
          )}
          onClick={() => onChange(item.value)}
        >
          {item.title}
        </button>
      ))}
      <div
        className={styles.bubble}
        ref={bubbleRef}
        style={{
          // @ts-ignore
          '--color': activeBgColor
        }}
      />
    </div>
  )
})
