import { DialogTitle } from '@mui/material'
import { CloseButton } from '@roolz/sdk/components/ui/buttons/CloseButton/CloseButton'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Close } from '@roolz/icons/Close'
import { InviteMembersForm } from '@/components/company-admin/forms/InviteMembersForm/InviteMembersForm'
import { Dialog } from '@/components/ui/Dialog/Dialog'
import { profilesStore } from '@/store/profiles/profiles.store'
import styles from '@/components/modals/InviteMembersAppeal/InviteMembersAppeal.module.scss'
import InviteMembersImage from '@/assets/images/invite-members.png'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
}

export const InviteMembersAppeal = ({
  open,
  setOpen,
}: Props) => {
  const { t } = useTranslation('modals')
  const companyId = profilesStore.activeCompanyId
  const inviteText = t('invite_members_appeal.invite')
  const cancelText = t('invite_members_appeal.cancel')

  if (!companyId) {
    return null
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xs'
      classes={{ paper: styles.paper }}
      scroll='body'
    >
      <div className={styles.header}>
        <img
          className={styles.image}
          src={InviteMembersImage}
          alt=''
        />

        <CloseButton
          className={styles.close}
          onClick={() => setOpen(false)}
        />
      </div>

      <DialogTitle className={styles.title}>
        {t('invite_members_appeal.title')}
      </DialogTitle>

      <div className={styles.content}>
        <div className={styles.description}>
          {t('invite_members_appeal.description')}
        </div>

        <InviteMembersForm
          companyId={companyId}
          acceptText={inviteText}
          cancelText={cancelText}

          onClose={onClose}
        />
      </div>
    </Dialog>
  )
}
