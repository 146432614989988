import { api } from '@/api'
import { TextField } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import { Loadable } from '@roolz/sdk/components'
import { usePricingCurrency } from '@roolz/sdk/components/pricing/hooks/usePricingCurrency'
import { usePlanPricing } from '@roolz/sdk/components/pricing/hooks/useUserPricing'
import { concatCurrency, getPricingCurrency, getPricingForCountry } from '@roolz/sdk/components/pricing/utils/plans'
import { toastError } from '@roolz/sdk/components/snackbars';
import Button, { Variant } from '@roolz/sdk/components/ui/buttons/Button/Button'
import { NumericInput } from '@roolz/sdk/components/ui/fields/NumericInput/NumericInput'
import { useLayoutStateRef, useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef';
import FocusLock, { FreeFocusInside } from 'react-focus-lock'
import { useTranslation } from 'react-i18next'
import { ChangeEvent, memo, ReactNode, useCallback, useEffect, useState } from 'react'
import cn from 'classnames'
import { billingStore } from '@/store/billing/billing.store'
import { billingService } from '@/store/billing/billing.service'
import { Label } from '@/components/ui/typography/Label/Label'
import { Dialog } from '@/components/ui/Dialog/Dialog'
import { Modal } from '@/components/hoc/Modal'
import styles from './TopUpBalance.module.scss'

interface Props {
  open: boolean
  setOpen: (open: boolean) => any
}

export const TopUpBalance = Modal(({
  open,
  setOpen,
}: Props) => {
  const { t } = useTranslation('modals')
  const { t: errorsT } = useTranslation('errors')

  const [loading, setLoading] = useState(false)
  const loadingRef = useLayoutStateRef(loading)

  const [value, setValue] = useState('')
  const [error, setError] = useState<string | null>(null)

  const [activePreset, setActivePreset] = useState<number | null>(null)

  const presetAmounts = [100, 200, 500, 1000]

  const onSubmit = useCallback(() => {
    if(loadingRef.current) return

    const val = Number.parseInt(value) || activePreset

    if(!val) {
      setError(t('top_up_balance.errors.empty_amount'))

      return
    }

    setLoading(true)

    billingService.topUpCreditsBalance(val)
      .catch(e => {
        toastError(e?.response?.data?.message ?? errorsT('insufficient_request'))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [value, activePreset])

  const currentPlan = billingStore.currentSpaceTariffPlan
  const pricing = usePlanPricing(currentPlan)
  const currency = usePricingCurrency({ pricing })

  const balance = pricing.price_credit

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    setActivePreset(null)
  }

  const setPreset = (amount: number) => {
    setValue('')
    setActivePreset(amount)
  }

  useEffect(() => {
    setError('')
  }, [value])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth='xs'
      classes={{ paper: styles.paper }}
      scroll='body'
    >
      {/* <FocusLock> */}
      <FreeFocusInside>
        <div className={styles.content}>
          <div className={styles.title}>
            {t('top_up_balance.title')}
          </div>
          <div className={styles.description}>
            {t('top_up_balance.description', {
              creditPrice: concatCurrency(currency, balance),
            })}
          </div>

          <div className={styles.presetAmounts}>
            {presetAmounts.map(amount => (
              <button
                key={amount}
                className={cn(styles.presetAmounts__item, {
                  [styles.presetAmounts__itemActive]: activePreset === amount,
                })}
                onClick={() => setPreset(amount)}
              >
                +
                {amount}
              </button>
            ))}
          </div>

          <div className={styles.field__wrapper}>
            <Label htmlFor='name'>{t('top_up_balance.custom_amount')}</Label>
            <TextField
              autoFocus
              value={activePreset || value}
              onChange={onInputChange}

              classes={{
                root: styles.field,
              }}
              placeholder={t('top_up_balance.custom_amount_placeholder')}
              fullWidth
              error={false}

              InputProps={{
                inputComponent: NumericInput,
                inputProps: {
                  positive: true,
                  integer: true,
                  className: styles.field__input,
                },
              }}
            />
          </div>

          <Collapse in={!!error} unmountOnExit timeout={200}>
            <div className={styles.error}>
              {error}
            </div>
          </Collapse>

          <Loadable loading={loading}>
            <div className={styles.actions}>
              <Button
                variant={Variant.secondary}
                onClick={() => setOpen(false)}
              >
                {t('top_up_balance.actions.cancel')}
              </Button>
              <Button
                disabled={loading}
                onClick={onSubmit}
              >
                {t('top_up_balance.actions.confirm')}
              </Button>
            </div>
          </Loadable>
        </div>
      </FreeFocusInside>
      {/* </FocusLock> */}
    </Dialog>
  )
})
