import { companyKnowledgeStore } from '@/store/knowledge/stores/company-knowledge.store'
import { ExchangeRoleSlug } from '@roolz/types/api/knowledge/companies'
import { Country } from '@roolz/types/custom'
import { Url } from '@roolz/types/scalars'
import * as yup from 'yup'

export interface CompanyForm {
  goal: string[] | null
  logo_path: Url | null
  name: string | null
  exchange_role: string
  employee_amount: string
  order_amount: string
  fleet_amount: string
  website?: string | null
  email?: string | null
  phone?: string | null
  company_form: string,
  registration_country: Country | null,
  legal_form_short?: string,
  legal_name?: string,
  registration_number?: string,
  tax_number?: string,

}

export const fullCompanySchema = {
  // goal: yup.array().of(yup.string()).min(1).required(),
  logo_path: yup.string().nullable(),
  name: yup.string().trim().required().min(2).max(55),
  exchange_role: yup.string().trim().required(),
  employee_amount: yup.string().trim().required(),
  order_amount: yup.string().trim().required(),
  fleet_amount: yup.string().when('exchange_role', (exchange_role, schema) =>
    companyKnowledgeStore.supportedExchangeRoles
      .filter(exchangeRole => exchangeRole.fleet_amount_active)
      .some((role) => exchange_role === role.slug)
        ? schema.required()
        : schema
  ),

  email: yup.string().trim().required().email().max(255),
  phone: yup.string().matches(/^\+?[0-9()-]{0,25}$/, 'Телефон не валидный').nullable().max(25),
  website: yup.string()
    .trim()
    .matches(/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/, {
      excludeEmptyString: true, message: 'url'
    })
    .max(255),

  company_form: yup.string().trim().required(),
  registration_country: yup.mixed().required(),
  legal_form_short: yup.string().trim().max(10),
  legal_name: yup.string().trim().max(255),
  registration_number: yup.string().trim().min(5).max(55),
  tax_number: yup.string().trim().max(55)
}

export type ShortCompanyForm = Pick<CompanyForm,
  'name' |
  'registration_country' |
  'company_form' |
  'email'
> & {
  exchange_role: ExchangeRoleSlug | null
}

export const shortCompanySchema = {
  name: fullCompanySchema.name,
  email: fullCompanySchema.email,
  registration_country: fullCompanySchema.registration_country,
  company_form: fullCompanySchema.company_form,
  exchange_role: fullCompanySchema.exchange_role,
}
