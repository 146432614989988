import PlayArrowFill from '@material-symbols/svg-400/rounded/play_arrow-fill.svg'
import { Tooltip } from '@mui/material'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import cn from 'classnames'
import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import InfoIcon from '@material-symbols/svg-400/rounded/info.svg'
import styles from './InfoTooltip.module.scss'

interface Props {
  className?: string
  iconClassName?: string
  title: string
  children?: ReactNode
  iconSize?: number
  strategy?: 'absolute' | 'fixed'
  icon?: ReactNode
}


const transComponents = {
  highlight: <span style={{ color: '#4778EE' }}/>,
  triangle: <MaterialSymbolIcon
    size={12}
    icon={<PlayArrowFill/>}
    style={{
      transform: 'rotate(-90deg) scale(2) translate(-10%, 0)',
      color: '#4778EE'
    }}
  />,
  // @ts-ignore
  grey: <grey/>
}

export const InfoTooltip = observer((props: Props) => {
  const {
    className,
    iconClassName,
    title,
    children,
    iconSize = 12,
    strategy = 'fixed',
    icon
  } = props

  return (
    <Tooltip
      arrow
      placement='top'
      describeChild
      title={<span className={styles.title}>{title}</span>}
      PopperProps={{
        modifiers: [{ name: 'offset', options: { offset: [0, -8] } }],
        popperOptions: {
          strategy
        }
      }}
    >
      <button className={cn(styles.root, className)}>
        {children}
        <MaterialSymbolIcon
          className={cn(styles.icon, iconClassName)}
          size={iconSize}
          icon={icon || <InfoIcon/>}/>
      </button>
    </Tooltip>
  )
})
