import {
  AddPaymentMethodRequest, CancelSubscriptionRequest, ChangeSubscriptionRequest,
  CheckBillingPermissionRequest,
  CheckBillingPermissionResponse, CheckIfHasViewedOffersRequest, CheckIfHasViewedOffersResponse,
  DeletePaymentMethodRequest,
  GetActiveTariffsResponse,
  GetCompanyBillingInfoRequest,
  GetCompanyBillingInfoResponse,
  GetPaymentMethodsRequest,
  GetPaymentMethodsResponse,
  GetTariffInfoResponse,
  GetTransactionListRequest,
  GetTransactionListResponse,
  PaymentMethod,
} from '@roolz/types/api/billing'
import { Offer } from '@roolz/types/api/exchange'
import { AxiosInstance } from 'axios'
import { ApiClients } from '@roolz/api/clients'

export class BillingApi {
  billing: AxiosInstance

  constructor(clients: ApiClients) {
    this.billing = clients.billing
  }

  getActiveTariffs() {
    return this.billing.get<GetActiveTariffsResponse>('/tariffs/active')
  }

  getTariff(tariff_id: string) {
    return this.billing.get<GetTariffInfoResponse>(`/tariff/${tariff_id}`)
  }

  checkIfHasViewOffers({ active_space_company_id, offer_ids }: CheckIfHasViewedOffersRequest) {
    return this.billing.post<CheckIfHasViewedOffersResponse>('/check/if-offers-view', offer_ids, {
      params: {
        active_space_company_id,
      },
    })
  }

  getCompanyBillingInfo(params: GetCompanyBillingInfoRequest) {
    return this.billing.get<GetCompanyBillingInfoResponse>('/me', { params })
  }

  topUpCredits({ params, body }: ChangeSubscriptionRequest) {
    return this.billing.post('/credits', body, { params })
  }

  changeTariffPlan({ params, body }: ChangeSubscriptionRequest) {
    return this.billing.post('/subscribe', body, { params, maxRedirects: 0 })
  }

  cancelSubscription(params: CancelSubscriptionRequest) {
    return this.billing.post('/unsubscribe', undefined, { params })
  }

  checkCompanyPermission(params: CheckBillingPermissionRequest) {
    return this.billing.get<CheckBillingPermissionResponse>('/check', { params })
  }

  checkPersonalPermission(params: CheckBillingPermissionRequest) {
    return this.billing.get<CheckBillingPermissionResponse>('/check/personal', { params })
  }

  getMyPaymentMethods(params: GetPaymentMethodsRequest) {
    return this.billing.get<GetPaymentMethodsResponse>('/payment-methods', { params })
  }

  addPaymentMethod({ params, body }: AddPaymentMethodRequest) {
    return this.billing.post<PaymentMethod>('/payment-method', body, { params })
  }

  deletePaymentMethod(id: string, params: DeletePaymentMethodRequest) {
    return this.billing.delete<PaymentMethod[]>(`/payment-method/${id}`, { params })
  }

  setDefaultPaymentMethod(id: string, params: DeletePaymentMethodRequest) {
    return this.billing.patch(`/payment-method/${id}/default`, undefined, { params })
  }

  getTransactionsList({ transaction_type, ...params }: GetTransactionListRequest) {
    return this.billing.get<GetTransactionListResponse>(`/transactions/${transaction_type}`, { params })
  }

  changeUserSeats({ body, params }: ChangeSubscriptionRequest) {
    return this.billing.post<GetTransactionListResponse>('/user-seats', body, { params })
  }
}
