import { LottieRef, LottieRefCurrentProps } from 'lottie-react'
import { MutableRefObject, useRef } from 'react'

export const useLottieControls = (ref: MutableRefObject<LottieRefCurrentProps | null>) => {
  const currentSegmentFrames = useRef<[number, number]>([0, 0])
  const isSegmentPlaying = useRef<boolean>(false)

  const playTo = (to: number) => {
    if(!ref?.current?.animationItem) {
      return
    }

    const { currentFrame: currentSegmentFrame } = ref.current.animationItem
    let currentAbsoluteFrame = currentSegmentFrame

    const [prevStart, prevEnd] = currentSegmentFrames.current

    if(isSegmentPlaying.current) {
      currentAbsoluteFrame = (prevStart < prevEnd ? prevStart : prevEnd) + currentSegmentFrame
    }

    currentSegmentFrames.current = [currentAbsoluteFrame, to]
    isSegmentPlaying.current = true

    ref.current.playSegments([currentAbsoluteFrame, to], true)
  }

  return {
    playTo
  }
}
