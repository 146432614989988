export const universalRoolzStaticUrl = process.env.NEXT_PUBLIC_ROOLZ_STATIC_URL ?? process.env.REACT_APP_ROOLZ_STATIC_URL

export const getCurrentCleanUrl = () => onlyPath(window.location.href)

export const onlyPath = (url: string) => url
  .replace(/\?.*/, '')
  .replace(/#.*/, '')

export const onlySearch = (url: string) => {
  if(url.indexOf('?') === -1) {
    return ''
  }

  return url
    .replace(/.*\?/, '')
    .replace(/#.*/, '')
}
