import data from '@roolz/icons/lottie/data/KPP.json'
import { forwardRef } from 'react'
import Lottie, { LottieComponentProps } from 'lottie-react'

export const RED_SIGNAL_END_FRAME = 54
export const YELLOW_SIGNAL_END_FRAME = 83
export const GREEN_SIGNAL_END_FRAME = 108
export const END_FRAME = 171

type Props = Omit<LottieComponentProps, 'animationData'>

export const KPP = (props: Props) => {
  return (
    <Lottie
      animationData={data}
      {...props}
    />
  )
}

export default KPP
