import { getPricingForCountry } from '@roolz/sdk/components/pricing/utils/plans'
import { TariffPlan, TariffPrice } from '@roolz/types/api/billing'
import { useMemo } from 'react'
import { useSdkContext } from '@roolz/sdk/SdkContext'

export const usePlanPricing = (plan: TariffPlan): TariffPrice => {
  const { pricingCountryAlpha2 } = useSdkContext()

  return useMemo((): TariffPrice => {
    return getPricingForCountry(plan, pricingCountryAlpha2)
  }, [plan.default_price, plan.pricing, pricingCountryAlpha2])
}
