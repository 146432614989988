import { CheckInCircle } from '@roolz/icons/CheckInCircle'
import { RoundedTriangle } from '@roolz/icons/RoundedTriangle'
import { PaymentPeriods, TariffPlan, TariffPlanType } from '@roolz/types/api/billing'
import cn from 'classnames'
import { filter } from 'lodash-es'
import React, { memo, ReactNode, useMemo, useState } from 'react'
import {
  TableHeadDesktop,
} from '@roolz/sdk/components/pricing/blocks/PlansScope/desktop/TableHeadDesktop/TableHeadDesktop'
import { TableHeadMobile } from '@roolz/sdk/components/pricing/blocks/PlansScope/mobile/TableHeadMobile/TableHeadMobile'
import { usePricingPeriodState } from '@roolz/sdk/components/pricing/hooks/usePricingPeriodState'
import {
  concatCurrency,
  getPlanActionText,
  getPricingCurrency,
  getPricingForCountry,
} from '@roolz/sdk/components/pricing/utils/plans'
import { InfoTooltip } from '@roolz/sdk/components/ui/InfoTooltip/InfoTooltip'
import { SdkTrans } from '@roolz/sdk/components/utils/SdkTrans'
import { useSdkContext, useSdkTranslation } from '@roolz/sdk/SdkContext'
import styles from './PlansScope.module.scss'

interface Props {
  hideRegister?: boolean

  isMobile: boolean
  tariffs: TariffPlan[]
  currentPlanType?: TariffPlanType
  currentPlanPeriod?: PaymentPeriods
  initialPaymentPeriod?: PaymentPeriods

  onConnect?: (tariff: TariffPlan, period: PaymentPeriods) => void
}

const transComponents = {
  highlight: <span style={{ color: '#4778EE' }}/>,
  triangle: <RoundedTriangle/>,
  check: <CheckInCircle/>,
  // @ts-expect-error Fucking crunch because react-i18next Trans don`t render string with '<' at all.
  // While next-translate does
  // P.S. See styles for content
  less: <less/>,
  // TODO refactor
  // @ts-ignore
  muted: <muted/>,
}

export const PlansScope = memo(({
  hideRegister,
  isMobile,
  tariffs,
  currentPlanType,
  currentPlanPeriod,
  initialPaymentPeriod,

  onConnect,
}: Props) => {
  const { t } = useSdkTranslation('pricing')

  const [period] = usePricingPeriodState(initialPaymentPeriod)
  const [activePlan, setActivePlan] = useState(TariffPlanType.Pro)

  const chapters = [
    {
      chapterPrefix: 'scope.public_exchange',
      rows: [
        'public_exchange',
        'cargo_publication',
        'transport_publication',
        'cargo_and_transport_search',
        'companies_list',
      ],
    },
    {
      chapterPrefix: 'scope.private_exchange',
      rows: [
        'private_exchange',
        'partnership',
        'private_offers',
        'extra_private_offer_cost',
      ],
    },
    {
      chapterPrefix: 'scope.common',
      rows: [
        'bidding',
        'auto_matching',
        'offer_templates_and_duplicates',
        'offer_and_bid_notifications',
        'offers_view',
        'support',
        'payment_by_invoice',
        'teaching',
        'dedicated_manager',
        'public_api',
        'flexible_permission_scope',
      ],
    },
  ]

  const tariffsForRender: TariffPlan[] = useMemo(() => {
    if(isMobile) {
      return filter(tariffs, { type: activePlan })
    }

    return tariffs
  }, [activePlan, isMobile])

  const { pricingCountryAlpha2, knowledge: { supportedCurrencies } } = useSdkContext()

  const getPlanRenderValues = (plan: TariffPlan) => {
    const pricing = getPricingForCountry(plan, pricingCountryAlpha2)
    const currencySymbol = getPricingCurrency(pricing, supportedCurrencies)

    const { price_credit } = pricing

    const extraPrivateOfferPrice = (Number(plan.scope.offer_private_crprice) * Number(price_credit)).toFixed(2)

    return {
      extraPrivateOfferPriceCr: plan.scope.offer_private_crprice,
      extraPrivateOfferPrice: concatCurrency(currencySymbol, extraPrivateOfferPrice),
      publicOffersViewsCount: plan.scope.offers_public_publish,
      privateOffersCount: plan.scope.offers_private_publish,
      offerViewsCount: plan.scope.offers_views,
    }
  }

  return (
    <div className={styles.root}>
      {isMobile && (
        <TableHeadMobile
          active={activePlan}
          onSelect={(plan: TariffPlan) => setActivePlan(plan.type)}
          tariffs={tariffs}
        />
      )}

      <table
        className={styles.table}
        style={{
          // @ts-ignore
          '--cols-count': tariffsForRender.length + 1,
        }}
      >

        {!isMobile && (
          <TableHeadDesktop
            hideRegister={hideRegister}
            tariffs={tariffs}
            onConnect={(tariff: TariffPlan) => onConnect?.(tariff, period)}
            currentPlanPeriod={currentPlanPeriod}
            initialPaymentPeriod={initialPaymentPeriod}
            currentPlanType={currentPlanType}
          />
        )}

        <tbody className={styles.tableBody}>
          {chapters.map(({
            chapterPrefix,
            rows,
          }) => (
            <>
              <TableChapter>
                {t(`${chapterPrefix}.chapterName`)}
              </TableChapter>

              {rows.map((name: string) => (
                <tr>
                  <RowName tooltip={t(`${chapterPrefix}.items.${name}.tooltip`)}>
                    <SdkTrans
                      i18nKey={`pricing:${chapterPrefix}.items.${name}.name`}
                      components={transComponents}
                    />

                  </RowName>

                  {tariffsForRender.map(tariff => (
                    <TableValue>
                      <SdkTrans
                        i18nKey={`pricing:${chapterPrefix}.items.${name}.values.${tariff.type}`}
                        components={transComponents}
                        values={{
                          ...getPlanRenderValues(tariff),
                        }}
                      />
                    </TableValue>
                  ))}
                </tr>
              ))}
            </>
          ))}

          <tr>
            {!isMobile && (
              <td className={styles.tdFill}/>
            )}

            {tariffsForRender.map(plan => (
              <PlanBottomButton
                hideRegister={hideRegister}
                isCurrent={plan.type === currentPlanType && period === currentPlanPeriod}
                isMobile={isMobile}
                plan={plan}
                onClick={() => onConnect?.(plan, period)}
              />
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  )
})

const TableChapter = ({ children }: {
  children: ReactNode
}) => (
  <tr>
    <td colSpan={6} className={styles.chapter}>
      {children}
    </td>
  </tr>
)

const RowName = ({
  tooltip,
  children,
}: {
  tooltip?: string
  children: ReactNode,
}) => (
  <td>
    <div className={styles.rowName}>
      <div>
        {children}
      </div>

      {tooltip && !!tooltip?.length && (
        <div className={styles.rowName__tooltip}>
          <InfoTooltip iconSize={16} title={tooltip}/>
        </div>
      )}
    </div>
  </td>
)

const TableValue = ({
  children,
}: {
  children: ReactNode
}) => (
  <td className={styles.tdValue}>
    {children}
    {/* <TransText */}
    {/*  text={children} */}
    {/*  components={{ */}
    {/*    br: <br/>, */}
    {/*    b: <b/>, */}
    {/*    span: <span/>, */}
    {/*    check: <CheckInCircle/>, */}
    {/*    triangle: <RoundedTriangle/>, */}
    {/*    muted: <muted/> */}
    {/*  }} */}
    {/* /> */}
  </td>
)

const PlanBottomButton = ({
  hideRegister = true,
  plan,
  onClick,
  isMobile = false,
  isCurrent,
}: {
  hideRegister?: boolean
  plan: TariffPlan,
  onClick?: () => void
  isMobile?: boolean
  isCurrent?: boolean
}) => {
  const { t } = useSdkTranslation('pricing')

  return (
    <td
      style={{
        padding: 0,
      }}
      colSpan={isMobile ? 2 : 1}
    >
      <button
        onClick={onClick}
        className={cn(styles.planBottomButton, {
          [styles.planBottomButtonFilled]: !isMobile && ![TariffPlanType.Free, TariffPlanType.Enterprise].includes(plan.type),
          [styles.planBottomButtonCurrent]: isCurrent,
          [styles.planBottomButtonHidden]: plan?.type === TariffPlanType.Free && !isCurrent && hideRegister,
        })}
      >
        {isCurrent
          ? t('plan_actions.current_plan')
          : t(getPlanActionText(plan.type))}
      </button>
    </td>
  )
}
