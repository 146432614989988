import { useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'

export function usePageTitle(title?: string, persist = false) {
  const { t } = useTranslation('business-meta')
  const defaultTitle = t('titles.default')

  function setTitle(title: string) {
    console.log('change title', title)
    setTimeout(() => document.title = title, 100)
  }

  useLayoutEffect(() => {
    if(title) {
      setTitle(title)
    }

    // Crunch for bad designed pages
    if(!persist) {
      return () => {
        setTitle(defaultTitle)
      }
    }
  }, [title])

  return {
    setTitle,
  }
}
