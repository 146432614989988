import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import { universalRoolzStaticUrl } from '@roolz/sdk/utils/urls'
import { memo } from 'react'
import cn from 'classnames'
import styles from './AnnualPeriodChip.module.scss'

interface Props {
  compact?: boolean
  className?: string
}

export const AnnualPeriodChip = memo(({
  compact,
  className,
}: Props) => {
  const { t } = useSdkTranslation('pricing')

  return (
    <div className={cn(styles.root, className, {
      [styles.rootCompact]: compact,
    })}
    >
      <div className={styles.chip}>
        {t('plans.annual_chip')}
      </div>
      <img
        src={`${universalRoolzStaticUrl}/images/pricing/arrow.svg`}
        alt=''
        className={styles.chipArrow}
      />
    </div>
  )
})
