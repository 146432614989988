import { Loadable } from '@roolz/sdk/components'
import { findCurrencySymbol } from '@roolz/sdk/components/pricing/utils/plans'
import { toastError } from '@roolz/sdk/components/snackbars'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { CloseButton } from '@roolz/sdk/components/ui/buttons/CloseButton/CloseButton'
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import axios from 'axios'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { FreeFocusInside } from 'react-focus-lock'
import { useTranslation } from 'react-i18next'
import { knowledgeStore } from '@/store/knowledge/stores/knowledge.store'
import { exchangeKnowledgeStore } from '@/store/knowledge/stores/exchange-knowledge.store'
import { Modal } from '@/components/hoc/Modal'
import { billingStore } from '@/store/billing/billing.store'
import styles from './StripeForm.module.scss'

const stripeKey = process.env.REACT_APP_STRIPE_KEY ?? ''

const stripePromise = loadStripe(stripeKey)

export const StripeForm = Modal(({
  setOpen,
}) => {
  const stripeOptions: StripeElementsOptions = {
    clientSecret: billingStore.stripePaymentIntentKey ?? '',
  }

  return (
    <Elements
      stripe={stripePromise}
      options={stripeOptions}
    >
      <CloseButton
        className={styles.close}
        onClick={() => setOpen(false)}
      />
      <Content/>
    </Elements>
  )
}, {
  closeOnBackdropClick: false,
  maxWidth: 'lg',
  classes: {
    paper: styles.root,
  },
})

const Content = observer(() => {
  const { t } = useTranslation('pricing')

  const stripe = useStripe()
  const elements = useElements()

  const [loading, setLoading] = useState(false)
  const [formReady, setFormReady] = useState(false)

  const amount = billingStore.stripePaymentAmount
  const currencyCode = billingStore.stripePaymentCurrency

  const currency = exchangeKnowledgeStore.findSupportedCurrencyBySlug(currencyCode || '')

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (!stripe || !elements) return

    setLoading(true)

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
      confirmParams: {
        return_url: billingStore.stripePaymentSuccessUrl ?? '',
      },
    })

    if (result.error) {
      toastError(result.error.message)
    } else {
      if(!billingStore.stripePaymentSuccessUrl) {
        return
      }

      const url = new URL(billingStore.stripePaymentSuccessUrl)
      url.searchParams.set('embeded', '1')

      const { headers } = await axios.get(url.toString())
      const location = headers.location

      if(!location) {
        return toastError('SOMETHING WENT WRONG')
      }

      billingStore.stripeManualRedirectHandler?.(location)

      billingStore.resetStripe()
    }

    setLoading(false)
  }

  return (
    <Loadable loading={!stripe || !elements || !formReady}>
      <div className={styles.content}>
        <form onSubmit={handleSubmit}>
          <FreeFocusInside>
            <PaymentElement
              onReady={() => setFormReady(true)}
            />
          </FreeFocusInside>

          <div className={styles.amountDescription}>
            {t('stripe_form.amount', {
              amount: currency
                ? `${currency?.symbol}${amount}`
                : `${amount} ${currencyCode}`,
            })}
          </div>

          <Loadable loading={loading}>
            <Button
              className={styles.submit}
              disabled={!stripe || !elements || loading}
            >
              {t('stripe_form.submit')}
            </Button>
          </Loadable>
        </form>
      </div>
    </Loadable>
  )
})
