import { IS_MOBILE } from '@roolz/sdk/utils/device'
import { lazy } from 'react'
import { RouteObject, useLocation, useNavigate } from 'react-router'
import { Home } from '@/pages/home/Home'
import { generatePath } from 'react-router-dom'
import ContactsViewAside from '@/components/contactList/ContactsViewAside'
import Fallback from '@/pages/Fallback'
import { CompanyAdmin } from '@/pages/home/companies/Admin/CompanyAdmin'
import CompanyView from '@/pages/modals/CompanyView'
import OfferMatches from '@/pages/modals/OfferMatches'
import OfferView from '@/pages/modals/OfferView'
import OfferEdit from '@/pages/modals/OfferEdit'
import ProfileView from '@/pages/modals/ProfileView'
import ProfileOffers from '@/pages/modals/ProfileOffers'
import Logout from '@/pages/Logout'

import { chatsService } from '@/store/chats/chats.service'
import { PrivateExchangeGuard } from '@/components/privateExchange/pages/PrivateExchange/PrivateExchangeGuard'
import type { To } from '@remix-run/router'

const CreateCompany = lazy(() => import('@/pages/home/companies/Create/CreateCompany'))
const CompanyPageUsers = lazy(() => import('@/pages/home/companies/Admin/members/CompanyAdminMembers'))
const CompanyList = lazy(() => import('@/pages/home/companies/AllCompanies/AllCompanies'))
const CompanyPageMain = lazy(() => import('@/pages/home/companies/Admin/main/CompanyAdminMain'))
const CompanyPageProfile = lazy(() => import('@/pages/home/companies/Admin/profile/CompanyAdminProfile'))
const CompanyAdminPricing = lazy(() => import('@/pages/home/companies/Admin/pricing/Pricing'))

const MyOffers = lazy(() => import('@/pages/home/offers/MyOffers/MyOffers'))
const MyBids = lazy(() => import('@/pages/home/bids/MyBids'))
const PublicExchange = lazy(() => import('@/pages/home/offers/PublicExchange/PublicExchange'))
const PrivateExchange = lazy(() => import('@/components/privateExchange/pages/PrivateExchange/PrivateExchange'))
const PrivateExchangeLayout = lazy(() => import('@/components/privateExchange/pages/PrivateExchange/PrivateExchangeLayout'))
const IncomingOffers = lazy(() => import('@/components/privateExchange/pages/IncomingOffers/IncomingOffers'))
const OutgoingOffers = lazy(() => import('@/components/privateExchange/pages/OutgoingOffers/OutgoingOffers'))
const PartnershipInvites = lazy(() => import('@/components/privateExchange/pages/PartnershipInvites/PartnershipInvites'))
const PartnershipGroups = lazy(() => import('@/components/privateExchange/pages/Groups/Groups'))

const AuthMethods = lazy(() => import('@/pages/home/profile/AuthMethods/AuthMethods'))
const BlackList = lazy(() => import('@/pages/home/profile/BlackList/BlackList'))
const EditProfile = lazy(() => import('@/pages/home/profile/EditProfile/EditProfile'))
const FillProfile = lazy(() => import('@/pages/home/profile/FillProfile/FillProfile'))
const MyProfile = lazy(() => import('@/pages/home/profile/MyProfile/MyProfile'))
const ProfileBilling = lazy(() => import('@/pages/home/profile/ProfileBilling/ProfileBilling'))

const SubscriptionSuccess = lazy(() => import('@/pages/payment/SubscriptionSuccess'))
const PaymentStatus = lazy(() => import('@/pages/payment/Status'))

// const Test = lazy(() => import('@/pages/home/Test'))

/**
 * Regular page routes declarations
 */

export interface ExtendedRoute extends RouteObject {
  name?: string,

  children?: ExtendedRoute[]
}

export const ROUTE_NAMES = {
  HOME: 'home',

  LOGOUT: 'logout',

  FILL_PROFILE: 'fill_profile',
  EDIT_PROFILE: 'edit_profile',
  MY_PROFILE: 'my_profile',
  PROFILE_OFFERS: 'profile_offers',
  MY_PROFILE_AUTH_METHODS: 'my_profile.auth_methods',
  PROFILE_VIEW: 'profile_view',
  CONTACT_LIST: 'contact_list',
  MY_PROFILE_BILLING: 'my_profile.billing',

  BLACK_LIST: 'black_list',
  PUBLIC_EXCHANGE: 'exchange',
  PRIVATE_EXCHANGE: 'private_exchange',
  PRIVATE_EXCHANGE_MAIN: 'private_exchange_main',
  PRIVATE_EXCHANGE_OUT: 'private_exchange_out',
  PRIVATE_EXCHANGE_IN: 'private_exchange_in',
  PARTNERSHIP_INVITES: 'partnership_invites',
  PARTNERSHIP_GROUPS: 'partnership_groups',
  MY_OFFERS: 'offers.my',
  MY_BIDS: 'bids.my',
  OFFER_VIEW: 'offers.view',
  OFFER_EDIT: 'offers.edit',
  OFFER_MATCHES: 'offer.matches',

  COMPANIES: 'companies',

  AUTH: 'auth',

  NOT_FOUND: 'not_found',

  COMPANY_CREATE: 'company-create',

  COMPANY_ADMIN: 'company-admin',
  COMPANY_ADMIN_MAIN: 'company.main',
  COMPANY_ADMIN_PROFILE: 'company.profile',
  COMPANY_ADMIN_USERS: 'company.users',
  COMPANY_ADMIN_PRICING: 'company.pricing',

  COMPANY_VIEW: 'company.view',

  SUBSCRIPTION_SUCCESS: 'payment.success',
  PAYMENT_STATUS: 'payment.status',
}

export const ROUTE_MODAL_PATHS = {
  [ROUTE_NAMES.OFFER_VIEW]: 'offers/:id',
}

// export function resolveRouteModalPath(name: string) {
//   const map = {
//     [ROUTE_NAMES.OFFER_VIEW]: 'offers/:id'
//   }
// }

// export const modalRoutes: {[key: string]: string} = {
//   [ROUTE_NAMES.OFFER_VIEW]: '/offers/:offer_id'
// }

export const routes: ExtendedRoute[] = [
  {
    path: '/payment/subscription/success',
    name: ROUTE_NAMES.SUBSCRIPTION_SUCCESS,
    element: <SubscriptionSuccess/>,
  },
  {
    path: '/payment/',
    name: ROUTE_NAMES.PAYMENT_STATUS,
    element: <PaymentStatus/>,
  },
  {
    path: '/',
    name: ROUTE_NAMES.HOME,
    element: <Home/>,
    children: [
      {
        path: '/me/fill',
        name: ROUTE_NAMES.FILL_PROFILE,
        element: <FillProfile/>,
      },
      {
        path: '/me/profile/edit',
        name: ROUTE_NAMES.EDIT_PROFILE,
        element: <EditProfile/>,
      },
      {
        path: '/me/profile',
        name: ROUTE_NAMES.MY_PROFILE,
        element: <MyProfile/>,
      },
      {
        path: '/me/profile/black_list',
        name: ROUTE_NAMES.BLACK_LIST,
        element: <BlackList/>,
      },
      {
        path: '/me/profile/auth',
        name: ROUTE_NAMES.MY_PROFILE_AUTH_METHODS,
        element: <AuthMethods/>,
      },
      {
        path: '/me/profile/billing',
        name: ROUTE_NAMES.MY_PROFILE_BILLING,
        element: <ProfileBilling/>,
      },
      {
        path: '/exchange',
        name: ROUTE_NAMES.PUBLIC_EXCHANGE,
        element: <PublicExchange/>,
      },
      {
        path: '/offers',
        name: ROUTE_NAMES.MY_OFFERS,
        element: <MyOffers/>,
      },
      {
        path: '/bidding',
        name: ROUTE_NAMES.MY_BIDS,
        element: <MyBids/>,
      },
      // {
      //   path: '/companies',
      //   name: ROUTE_NAMES.COMPANIES,
      //   element: <CompanyList/>
      // },
      {
        path: '/companies',
        name: ROUTE_NAMES.COMPANIES,
        element: <CompanyList/>,
      },
      {
        path: '/companies/*',
        element: <CompanyList/>,
      },

      // ...companyPages
      //   .map((category, i) => ({
      //     path: category.path,
      //     name: i === 0 ? ROUTE_NAMES.COMPANIES : undefined,
      //     element: <CompanyList/>,
      //     handle: { category }
      //   })),
      {
        path: '/private_exchange',
        name: ROUTE_NAMES.PRIVATE_EXCHANGE,
        element: <PrivateExchangeGuard/>,
        children: [
          {
            path: '',
            element: <PrivateExchangeLayout/>,
            children: [
              {
                path: '',
                name: ROUTE_NAMES.PRIVATE_EXCHANGE_MAIN,
                element: <PrivateExchange/>,
              },
              {
                path: 'incoming',
                name: ROUTE_NAMES.PRIVATE_EXCHANGE_IN,
                element: <IncomingOffers/>,
              },
              {
                path: 'outgoing',
                name: ROUTE_NAMES.PRIVATE_EXCHANGE_OUT,
                element: <OutgoingOffers/>,
              },
            ],
          },
          {
            path: 'partnership_invites',
            name: ROUTE_NAMES.PARTNERSHIP_INVITES,
            element: <PartnershipInvites/>,
          },
          {
            path: 'partnership_groups',
            name: ROUTE_NAMES.PARTNERSHIP_GROUPS,
            element: <PartnershipGroups/>,
          },
        ],
      },
      /*      {
       path: '/company_invitation/accept/:hash',
       name: ROUTE_NAMES.COMPANY_INVITATION_ACCEPT,
       element: <Hash/>
       },
       {
       path: '/company_invitation/decline/:hash',
       name: ROUTE_NAMES.COMPANY_INVITATION_DECLINE,
       element: <Hash/>
       }, */
      {
        name: ROUTE_NAMES.NOT_FOUND,
        path: '*',
        element: <Fallback/>,
      },
    ],
  },
  {
    path: '/create-company',
    name: ROUTE_NAMES.COMPANY_CREATE,
    element: <CreateCompany/>,
  },
  {
    path: '/company-admin/:company_id',
    name: ROUTE_NAMES.COMPANY_ADMIN,
    element: <CompanyAdmin/>,
    children: [
      {
        path: 'main',
        name: ROUTE_NAMES.COMPANY_ADMIN_MAIN,
        element: <CompanyPageMain/>,
      },
      {
        path: 'profile',
        name: ROUTE_NAMES.COMPANY_ADMIN_PROFILE,
        element: <CompanyPageProfile/>,
      },
      {
        path: 'users',
        name: ROUTE_NAMES.COMPANY_ADMIN_USERS,
        element: <CompanyPageUsers/>,
      },
      {
        path: 'pricing',
        name: ROUTE_NAMES.COMPANY_ADMIN_PRICING,
        element: <CompanyAdminPricing/>,
      },
    ],
  },
  {
    path: '/logout',
    name: ROUTE_NAMES.LOGOUT,
    element: <Logout/>,
  },
]

export const modalRoutes: ExtendedRoute[] = [
  {
    path: '/offer/:offer_id',
    name: ROUTE_NAMES.OFFER_VIEW,
    element: <OfferView/>,
  },
  {
    path: '/offer/:offer_id/edit',
    name: ROUTE_NAMES.OFFER_EDIT,
    element: <OfferEdit/>,
  },
  {
    path: '/offer/:offer_id/matches',
    name: ROUTE_NAMES.OFFER_MATCHES,
    element: <OfferMatches/>,
  },
  {
    path: '/u/:profile_id',
    name: ROUTE_NAMES.PROFILE_VIEW,
    element: <ProfileView/>,
  },
  {
    path: '/u/:profile_id/offers',
    name: ROUTE_NAMES.PROFILE_OFFERS,
    element: <ProfileOffers/>,
  },
  {
    path: '/b/:company_id',
    name: ROUTE_NAMES.COMPANY_VIEW,
    element: <CompanyView/>,
  },
  {
    path: '/contacts',
    name: ROUTE_NAMES.CONTACT_LIST,
    element: <ContactsViewAside/>,
  },

  // {
  // path: '/profile/:profile_id',
  // name: ROUTE_NAMES.PROFILE_VIEW,
  // element: <ProfileView/>
  // },
]

/**
 * Map, where key is route name, and value is absolute path to this route
 */
export const NAME_PATH_MAP: { [name: string]: string } = {}

/**
 * Index name and paths of routes on start of app
 *
 * @param routes
 * @param parentPath
 */
function recursiveScanRoutesObject(routes: ExtendedRoute[], parentPath = ''): void {
  routes.forEach((route: ExtendedRoute) => {
    // TODO try to delete it and configure this check in linter for routes object
    if(route.path === undefined) {
      throw new Error('Route can`t have no path')
    }

    let currentPath = `${parentPath}/${route.path.replace(/^\//, '')}`
    currentPath = currentPath.replaceAll('//', '/')

    if(route.name !== undefined) {
      NAME_PATH_MAP[route.name] = currentPath
    }

    if(Array.isArray(route.children)) {
      recursiveScanRoutesObject(route.children, currentPath)
    }
  })
}

recursiveScanRoutesObject(routes)
recursiveScanRoutesObject(modalRoutes)

interface searchParams {
  name: string
}

export function generatePathByName(name: string, params?: any) {
  return generatePath(resolvePathByName({ name }), params)
}

export function resolvePathByName(params: searchParams | string): string {
  if(typeof params === 'string') {
    params = {
      name: params,
    }
  }

  if(NAME_PATH_MAP[params.name] === undefined) {
    throw new Error(`Route with name ${params.name} not found`)
  }

  return NAME_PATH_MAP[params.name]
}

export function resolvePathWithParams(params: searchParams | string, args: Record<string, string>) {
  const link = resolvePathByName(params)

  return Object.keys(args).reduce((prev, curr) => prev.replace(`:${curr}`, args[curr]), link)
}

export function useNavigateToModal() {
  const navigate = useNavigate()
  const location = useLocation()

  return (pathname: To, params?: {
    replace?: boolean,
  }) => {
    if(location.pathname === pathname) {
      return
    }

    // TODO CRUNCH, REMOVE THIS ASAP
    if(IS_MOBILE) {
      chatsService.setActiveChat(null)
    }

    return navigate(pathname, {
      ...params,
      state: {
        backgroundLocation: location?.state?.backgroundLocation ?? location,
      },
    })
  }
}
