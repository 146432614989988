import { BillingPermissionName } from '@roolz/types/api/billing'
import { AxiosError } from 'axios'
import { billingStore } from '@/store/billing/billing.store'

export const paymentRequiredResponseHandler = (e: AxiosError) => {
  const status = e?.response?.status
  const permission = (e?.response?.data as any)?.message

  if(status === 402) {
    console.log(e)

    if(billingStore.billingError) {
      console.log('Billing error already visible')

      return false
    }

    billingStore.billingError = !!permission && Object.values(BillingPermissionName)
      .includes(permission)
      ? permission
      : 'unknown'

    return
  }

  throw e
}
