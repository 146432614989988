import { getPricingCurrency } from '@roolz/sdk/components/pricing/utils/plans'
import { useSdkContext } from '@roolz/sdk/SdkContext'
import { TariffPrice } from '@roolz/types/api/billing'
import { Currency } from '@roolz/types/api/knowledge/exchange'
import { useMemo } from 'react'

interface Props {
  pricing: TariffPrice
}

export const usePricingCurrency = ({
  pricing
}: Props) => {
  const { knowledge: { supportedCurrencies } } = useSdkContext()

  return getPricingCurrency(pricing, supportedCurrencies)
}
