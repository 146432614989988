import { TariffPlan, TariffPlanType, TariffPrice } from '@roolz/types/api/billing'
import { Currency } from '@roolz/types/api/knowledge/exchange'
import { findIndex } from 'lodash-es'

type PlanVariant = 'most_popular' | 'most_valuable'

const priceTypes: Record<TariffPlanType, string> = {
  [TariffPlanType.Free]: '',
  [TariffPlanType.Beginner]: 'per_user',
  [TariffPlanType.Pro]: 'per_user',
  [TariffPlanType.Expert]: 'per_user',
  [TariffPlanType.Enterprise]: 'per_company',
}

const planVariants: Partial<Record<TariffPlanType, PlanVariant>> = {
  [TariffPlanType.Pro]: 'most_popular',
  [TariffPlanType.Expert]: 'most_valuable',
}

const texts = {
  [TariffPlanType.Free]: 'register',
  [TariffPlanType.Beginner]: 'connect',
  [TariffPlanType.Pro]: 'connect',
  [TariffPlanType.Expert]: 'connect',
  [TariffPlanType.Enterprise]: 'get_info',
}

export const getPlanVariant = (planType: TariffPlanType) => planVariants?.[planType]
export const getPriceType = (planType: TariffPlanType) => priceTypes?.[planType]
export const getPlanActionText = (planType: TariffPlanType) => `pricing:plan_actions.${texts[planType]}`

export const sortPlansForMobile = (tariffs: TariffPlan[]) => {
  const proIndex = findIndex(tariffs, { type: TariffPlanType.Pro })

  if (proIndex !== -1) {
    const res = [...tariffs]

    return [
      ...res.splice(proIndex, 1),
      ...res,
    ]
  }

  return tariffs
}
export const getPricingForCountry = (plan: TariffPlan, countryAlpha2?: string): TariffPrice => {
  const defaultPrice = {
    ...plan.default_price,
    currency: 'USD',
    country_code: '',
  }

  let countryTariff

  if(countryAlpha2) {
    countryTariff = plan.pricing.find(
      ({ country_code }) => country_code.toLowerCase() === countryAlpha2.toLowerCase(),
    )
  }

  return countryTariff || defaultPrice
}

export const findCurrencySymbol = (slug: string, currencies: Currency[]) => currencies
  .find(item => item.slug.toLowerCase() === slug.toLowerCase())?.symbol ?? slug

export const getPricingCurrency = (pricing: TariffPrice, supportedCurrencies: Currency[]) => findCurrencySymbol(pricing.currency, supportedCurrencies)

export const concatCurrency = (currency: string, amount: string | number) => (currency?.length > 1 ? `${amount} ${currency}` : `${currency}${amount}`)

export const enterprisePlanModel: TariffPlan = {
  type: TariffPlanType.Enterprise,
  version: 1,
  is_custom: true,
  default_price: {
    price_monthly: '',
    price_annual: '',
    price_credit: '',
    min_seats: 0,
    price_annual_to_month: '0',
  },
  pricing: [],
  scope: {
    offers_public_publish: 0,
    is_verification: false,
    offers_private_publish: 0,
    offer_private_crprice: '0',
    private_exch_groups: 0,
    offers_views: 0,
    offer_priority: 0,
  },
}
