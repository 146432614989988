import { universalRoolzStaticUrl } from '@roolz/sdk/utils/urls';

const urlToStatic = (path: string) => universalRoolzStaticUrl + path

export const getDocLinks = (countryAlpha2: string) => {
  switch(countryAlpha2.toLowerCase()) {
    case 'by': {
      return {
        terms: urlToStatic('/terms-of-use-by.html'),
        privacy: urlToStatic('/privacy-policy-by.html'),
        public_offer: urlToStatic('/public-offer-by.html'),
      }
    }
    case 'ru': {
      return {
        terms: urlToStatic('/terms-of-use-ru.html'),
        privacy: urlToStatic('/privacy-policy-ru.html'),
      }
    }
    case 'am': {
      return {
        terms: urlToStatic('/terms-of-use-am.html'),
        privacy: urlToStatic('/privacy-policy-am.html'),
      }
    }
  }

  return {
    terms: urlToStatic('/terms-of-use.html'),
    privacy: urlToStatic('/privacy-policy.html'),
  }
}
