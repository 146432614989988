import { universalRoolzStaticUrl } from '@roolz/sdk/utils/urls'
import { get, set, remove } from './cookie'

const AFTER_AUTH_URL_KEY = 'after_auth_redirect_key'

// @ts-ignore
const { hostname } = new URL(universalRoolzStaticUrl)
const domain = hostname.split('.').slice(-2).join('.')

export const setAfterAuthURL = (url: string) => set(AFTER_AUTH_URL_KEY, url, { expires: '0', domain })

export const getAfterAuthURL = () => get(AFTER_AUTH_URL_KEY) || null

export const removeAfterAuthURL = () => remove(AFTER_AUTH_URL_KEY, { domain })
