import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { MutableRefObject, useEffect, useState } from 'react'

// returns if passed element is in hover state
export function useHover(el: MutableRefObject<HTMLElement | null>, enabled = true) {
  const [isHover, setIsHover] = useState<boolean>(false)
  const isHoverRef = useStateRef<boolean>(isHover)

  function handleMouseEnter() {
    if(!isHoverRef.current) {
      setIsHover(true)
    }
  }
  function handleMouseLeave() {
    if(isHoverRef.current) {
      setIsHover(false)
    }
  }

  useEffect(() => {
    if(!enabled) {
      return
    }

    if(el?.current) {
      el.current.addEventListener('mouseenter', handleMouseEnter)
      el.current.addEventListener('mouseleave', handleMouseLeave)
    }

    return () => {
      if(el?.current) {
        el.current.removeEventListener('mouseenter', handleMouseEnter)
        el.current.removeEventListener('mouseleave', handleMouseLeave)
      }
    }
  }, [el?.current])

  return isHover
}
