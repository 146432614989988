import { api } from '@/api';
import { ROUTE_NAMES, routes } from '@/config/routes';
import { toastError } from '@roolz/sdk/components/snackbars';
import { useModalControl } from '@roolz/sdk/hooks/useModalControl'
import dayjs from '@roolz/sdk/plugins/dayjs';
import {
  AfterCompanyCreateActions,
  getAfterCompanyCreateAction,
  removeAfterCompanyCreateAction,
} from '@roolz/sdk/utils/afterCompanyCreateAction'
import { getCompanyAfterInvite, removeCompanyAfterInvite } from '@roolz/sdk/utils/afterEmailInviteRedirect';
import { TariffPlanType } from '@roolz/types/api/billing';
import { CompanyFormSlug } from '@roolz/types/api/knowledge/companies';
import { observer } from 'mobx-react-lite';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchRoutes, useLocation, useNavigate } from 'react-router';
import { AppealModalManager, MODAL_NAMES } from '@/components/AppealModalManager';
import { ChangeTariffProcess } from '@/components/company-admin/pricing/processes/ChangeTariffProcess'
import { CreateCompanyAppeal } from '@/components/modals/companies/CreateCompanyAppeal/CreateCompanyAppeal';
import { CreateOffersAppeal } from '@/components/modals/CreateOffersAppeal/CreateOffersAppeal';
import { InvitedModalAccept } from '@/components/modals/InvitedModalAccept/InvitedModalAccept';
import { InviteMembersAppeal } from '@/components/modals/InviteMembersAppeal/InviteMembersAppeal';
import { useGlobalModals } from '@/global-modals/useGlobalModals';
import { billingStore } from '@/store/billing/billing.store';
import { clientSettings } from '@/store/client-settings/client-settings';
import { companiesStore } from '@/store/companies/companies.store';
import { myCompaniesStore } from '@/store/companies/my_companies.store';
import { profilesStore } from '@/store/profiles/profiles.store';
import { systemStore } from '@/store/system/system.store';
import { getNow } from '@/utils/date';

const COMPANY_OWNERS_COUNT = 1

export const AppealModalsContainer = observer(() => {
  const { t: errorsT } = useTranslation('errors')
  const [activeModal, setActiveModal] = useState<ReactElement | null>(null)
  const [init, setInit] = useState(false)

  const isFilled = profilesStore.my_profile?.is_filled

  useEffect(() => {
    if(isFilled) {
      setInit(true)
    }
  }, [isFilled])

  const location = useLocation()
  const activeSpace = profilesStore.activeCompanyId
  const company = companiesStore.myCompany
  const companyId = company?.id
  const justSignedUp = clientSettings.isJustSignedUp

  const isCompanyAdminRoute = useMemo(() => !!matchRoutes(routes, location.pathname)
    ?.find(({ route }) => route.name === ROUTE_NAMES.COMPANY_ADMIN), [location])

  const isCompanyCreateRoute = useMemo(() => !!matchRoutes(routes, location.pathname)
    ?.find(({ route }) => route.name === ROUTE_NAMES.COMPANY_CREATE), [location])

  // const createCompanyModalController = useCallback((next?: () => void) => {
  //   const shouldShowCreateCompanyModal = profilesStore.isMyProfileFilled
  //     && profilesStore.my_profile
  //     && !profilesStore.my_profile.companies?.length
  //     && justSignedUp
  //
  //   const afterAuthCallback = () => {
  //     const redirectURL = getAfterAuthURL()
  //
  //     if(redirectURL) {
  //       removeAfterAuthURL()
  //       navigate(redirectURL)
  //     }
  //   }
  //
  //   if(shouldShowCreateCompanyModal) {
  //     globalModalsManager.open(GLOBAL_MODALS_NAMES.CREATE_COMPANY_NEW, {
  //       props: {
  //         afterAuthCallback
  //       }
  //     })
  //
  //     return
  //   } else {
  //     profilesStore.isMyProfileFilled && afterAuthCallback()
  //   }
  //
  //   next?.()
  // }, [
  //   profilesStore.isMyProfileFilled,
  //   myCompaniesStore.companies,
  //   profilesStore.my_profile,
  //   justSignedUp
  // ])

  const createCompanyAppealController = useCallback((next?: () => void) => {
    if(
      profilesStore.isMyProfileFilled
      && !myCompaniesStore.companies?.length
      && !profilesStore.my_profile?.company_invitations?.length
      && dayjs(profilesStore.my_profile?.created_at).diff(getNow(), 'minutes') < 20
      && AppealModalManager.isModalAllowedToOpen(MODAL_NAMES.createCompanyAppeal)
      && !justSignedUp
    ) {
      const handleClose = (open: boolean): void => {
        if(!open) {
          AppealModalManager.setIsModalAllowedToOpen(MODAL_NAMES.createCompanyAppeal, false)
          setActiveModal(null)
        }
      }

      return setActiveModal(
        <CreateCompanyAppeal
          open
          setOpen={handleClose}
        />,
      )
    }

    next?.()
  }, [
    profilesStore.my_profile,
    profilesStore.isMyProfileFilled,
    myCompaniesStore.companies,
    profilesStore.my_profile?.company_invitations,
    justSignedUp,
  ])

  const createOffersAppealController = useCallback((next?: () => void) => {
    if(
      profilesStore.isMyProfileFilled
      && !isCompanyAdminRoute
      && myCompaniesStore.companies?.length
      && AppealModalManager.isModalAllowedToOpen(MODAL_NAMES.createOffersAppeal)
    ) {
      return setActiveModal(
        <CreateOffersAppeal
          open
          onClose={() => {
            AppealModalManager.setIsModalAllowedToOpen(MODAL_NAMES.createOffersAppeal, false)
            setActiveModal(null)
          }}
        />,
      )
    }

    next?.()
  }, [
    isCompanyAdminRoute,
    profilesStore.my_profile,
    profilesStore.isMyProfileFilled,
    myCompaniesStore.companies,
  ])

  const invitedCompanyId = getCompanyAfterInvite()
  const invited = profilesStore.my_profile?.companies?.find(({ id }) => invitedCompanyId === id)

  const invitedCompanyController = useCallback(async (next?: () => void) => {
    if(invited && !activeModal) {
      return setActiveModal(
        <InvitedModalAccept
          company={invited}
          onClose={() => {
            removeCompanyAfterInvite()
            setActiveModal(null)
          }}
        />,
      )
    }

    next?.()
  }, [invited])

  const inviteMembersAppealController = useCallback(async (next?: () => void) => {
    const tariffType = billingStore.billing?.tariff_plan?.type
    const isCompanyPublicForm = !!company?.company_form && CompanyFormSlug.self_employed !== company.company_form

    if(
      !companyId
      || !isCompanyAdminRoute
      || !isCompanyPublicForm
      || !AppealModalManager.getIsInviteMembersAllowed(company.id)
      || tariffType !== TariffPlanType.Free
    ) {
      next?.()

      return
    }

    try {
      const { data: { total } } = await api.companies.getCompanyMembers(companyId, {
        limit: 1,
      })

      const hasMembers = total > COMPANY_OWNERS_COUNT

      if(!hasMembers) {
        return setActiveModal(
          <InviteMembersAppeal
            open
            setOpen={() => {
              companyId && AppealModalManager.setInviteMembersRestricted(companyId)
              setActiveModal(null)
            }}
          />,
        )
      }
    } catch(e: any) {
      toastError(e.response?.data?.error_msg ?? errorsT('insufficient_request'))
    }
  }, [
    isCompanyAdminRoute,
    company,
  ])

  const billingModalController = useCallback(async (next: () => void) => {
    const action = getAfterCompanyCreateAction()

    if(action === AfterCompanyCreateActions.showBilling && activeSpace && !isCompanyCreateRoute) {
      removeAfterCompanyCreateAction()

      return setActiveModal(
        <ChangeTariffProcess open/>,
      )
    }

    next()
  }, [activeSpace])

  const pipe = [
    // createCompanyModalController,
    billingModalController,
    createCompanyAppealController,
    invitedCompanyController,
    createOffersAppealController,
    inviteMembersAppealController,
  ]

  useEffect(() => {
    if(!init || activeModal) return

    const next = (i: number) => {
      pipe[i]?.(() => next(i + 1))
    }

    next(0)
  }, [...pipe, activeModal, init])

  if(!systemStore.isAppealsModalControllerEnabled) {
    return null
  }

  return activeModal
})
