import Info from '@material-symbols/svg-400/rounded/info.svg'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import cn from 'classnames'
import { memo } from 'react'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import styles from './MonthlyPriceForYearPanel.module.scss'

interface Props {
  visible?: boolean
  className?: string
}

export const MonthlyPriceForYearPanel = memo(({
  visible = true,
  className,
}: Props) => {
  const { t } = useSdkTranslation('pricing')

  return (
    <div
      className={cn(styles.yearPricePanel, className, {
        [styles.yearPricePanelVisible]: visible,
      })}
    >
      <MaterialSymbolIcon size={16} icon={<Info/>}/>
      {t('plans.per_month_for_annual_plan')}
    </div>
  )
})
