import { useModalControl } from '@roolz/sdk/hooks/useModalControl'
import { BillingPermissionName } from '@roolz/types/api/billing'
import { observer } from 'mobx-react-lite'
import { useCallback } from 'react'
import { useChangeTariff } from '@/components/company-admin/pricing/hooks/useChangeTariff'
import { billingStore } from '@/store/billing/billing.store'
import { BillingLimitReached } from '@/components/modals/billing/BillingLimitReached/BillingLimitReached'
import { TopUpBalance } from '@/components/modals/billing/TopUpBalance/TopUpBalance'

export const BillingErrorContainer = observer(() => {
  const topUpControls = useModalControl()

  const { changePlan } = useChangeTariff()

  const handleTopUp = useCallback(() => {
    billingStore.billingError = null
    topUpControls.show()
  }, [])

  const onLimitReachedClose = useCallback(() => {
    billingStore.billingError = null
  }, [])

  return (
    <>
      <BillingLimitReached
        onTopUp={handleTopUp}
        onChangePlan={changePlan}
        permissionName={billingStore.billingError as BillingPermissionName}
        open={!!billingStore.billingError}
        setOpen={onLimitReachedClose}
      />

      {/* <AddUsersLimitReached */}
      {/*   open={billingStore.lackingSeatsCount !== undefined} */}
      {/*   setOpen={() => billingStore.lackingSeatsCount = null} */}
      {/*   seatsCount={billingStore.lackingSeatsCount} */}
      {/* /> */}

      <TopUpBalance {...topUpControls.state}/>
    </>
  )
})
