import { TariffPlan } from '@roolz/types/api/billing'
import { findIndex } from 'lodash-es'
import { memo, useEffect, useRef } from 'react'
import cn from 'classnames'
import animateScrollTo from 'animated-scroll-to'
import { MostPopularLabel } from '@roolz/sdk/components/pricing/ui/MostPopularLabel/MostPopularLabel'
import { MostValuableLabel } from '@roolz/sdk/components/pricing/ui/MostValuableLabel/MostValuableLabel'
import { getPlanVariant } from '@roolz/sdk/components/pricing/utils/plans'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import styles from './TableHeadMobile.module.scss'

interface Props {
  active: TariffPlan['type']
  onSelect?: (plan: TariffPlan) => void

  tariffs: TariffPlan[]
  className?: string
}

export const TableHeadMobile = memo(({
  active,
  onSelect,

  tariffs,
  className,
}: Props) => {
  const { t } = useSdkTranslation('pricing')

  const activeI = findIndex(tariffs, { type: active })

  const contentRef = useRef<HTMLDivElement | null>(null)
  const activeItemRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    const content = contentRef.current
    const activeEl = activeItemRef.current

    if(!content || !activeEl) return

    const elRect = activeEl.getBoundingClientRect()
    const contRect = content.getBoundingClientRect()

    const offset = elRect.left - contRect.left
    const scrollPosition = offset - (contRect.width / 2) + (elRect.width / 2);

    animateScrollTo(0, {
      elementToScroll: content,
      horizontalOffset: scrollPosition,
    })

    // content.scrollBy({ left: scrollPosition })
  }, [active])

  return (
    <div
      className={cn(styles.root, className)}
      style={{
        // @ts-ignore
        '--active-i': activeI,
        '--count': tariffs.length,
      }}
    >
      <div
        className={styles.content}
        ref={contentRef}
      >
        {tariffs.map(item => (
          <button
            ref={item.type === active ? activeItemRef : undefined}
            className={cn(styles.item, {
              [styles.itemActive]: active === item.type,
            })}
            onClick={() => onSelect?.(item)}
          >
            <span className={styles.item__text}>
              {t(`plans.items.${item.type}.title`)}
            </span>

            {getPlanVariant(item.type) === 'most_popular' && (
              <MostPopularLabel className={styles.label}/>
            )}

            {getPlanVariant(item.type) === 'most_valuable' && (
              <MostValuableLabel className={styles.label}/>
            )}
          </button>
        ))}
      </div>

      <div className={styles.lighterLeft}/>
      <div className={styles.lighterRight}/>
    </div>
  )
})
