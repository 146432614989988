import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { useStateRef } from '@roolz/sdk/hooks/helpers/useStateRef'
import { observer } from 'mobx-react-lite'
import { ReactElement, useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { ModalType } from '@/global-modals/const'
import { globalModalsHistory } from '@/global-modals/globalModalsHistory'
import { useGlobalModals } from '@/global-modals/useGlobalModals'
import { useLastLocation } from '@/hooks/useLastLocation'

type PropFunc<Props> = (props: Props & {
  onClose: () => void
}) => ReactElement | null

interface Config {
  saveToModalsHistory?: boolean
}

export function OverlayModalPage<Props>(PageFunc: PropFunc<Props>, config?: Config) {
  return observer((props: Props): ReactElement | null => {
    // Waiting one tick for global modals been registered
    const [ready, setReady] = useState(false)
    useEffect(() => {
      setReady(true)
    }, [])

    const globalModals = useGlobalModals()

    const location = useLocation()
    const { getLastLocation } = useLastLocation()
    const locationRef = useStateRef(location)
    const navigate = useNavigate()

    const fallbackLocation = {
      pathname: resolvePathByName(ROUTE_NAMES.MY_OFFERS),
      search: '',
      hash: '',
      state: {},
      key: '',
    }

    const onClose = useCallback(() => {
      const lastLocation = getLastLocation()

      const bgLocation = locationRef.current.state?.backgroundLocation

      const location = bgLocation || lastLocation || fallbackLocation
      const state = bgLocation?.state || undefined

      navigate(location, { state })
    }, [location])

    useEffect(() => {
      if(config?.saveToModalsHistory === false) {
        return
      }

      if(globalModalsHistory.overlay.at(-2) === location?.pathname) {
        // Probably fix for click < button in browser
        // If new location equals to previous (not current) location, count it as go back

        globalModalsHistory.overlay.pop()

        return
      }
      if(globalModalsHistory.overlay.at(-1) !== location?.pathname) {
        globalModalsHistory.overlay.push(location?.pathname)
      }
    }, [location])

    useEffect(() => {
      if(!location?.state?.backgroundLocation) {
        const lastLocation = getLastLocation()

        navigate(location, {
          state: {
            backgroundLocation: lastLocation
              ? lastLocation?.state?.backgroundLocation ?? lastLocation
              : fallbackLocation,
          },
          replace: true,
        })
      }
    }, [])

    useEffect(() => {
      globalModals.visibleModals
        .filter(item => item.modal.meta.type === ModalType.POPUP)
        .forEach(modal => {
          // console.log('close modal', modal.modal.id)
          globalModals.close(modal.modal.id)
        })
    }, [])

    if(!ready) return null

    return <PageFunc {...props} onClose={onClose}/>
  })
}
