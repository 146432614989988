import { api } from '@/api'
import { resolvePathByName, ROUTE_NAMES } from '@/config/routes'
import { toastError } from '@roolz/sdk/components/snackbars'
import { getCurrentCleanUrl } from '@roolz/sdk/utils/urls'
import { Billing, BillingPermissionName, PaymentPeriods, TariffPlanType } from '@roolz/types/api/billing'
import { Company } from '@roolz/types/api/companies'
import { AxiosResponse } from 'axios'
import { profilesStore } from '@/store/profiles/profiles.store'
import { billingStore } from '@/store/billing/billing.store'
import { bus } from '@/events'

// const baseUrl = process.env.REACT_APP_ROOLZ_BUSINESS_URL

export const TOP_UP_STATUS_PARAM = 'topUpStatus'
export const UP_SEATS_STATUS_PARAM = 'upSeats'
export const UP_SEATS_COUNT_STATUS_PARAM = 'upSeatsCount'
export const SUBSCRIBE_STATUS_PARAM = 'subscribeStatus'
export const ADD_PAYMENT_METHOD_STATUS_PARAM = 'addPaymentMethodStatus'

interface HandlePaymentResultParams {
  preferNewTab?: boolean
  onRedirect?: (location: string) => void
}

const generateStatusUrl = (statusKey: string, statusType: 'success' | 'error'): URL => {
  const url = new URL(getCurrentCleanUrl())

  url.pathname = resolvePathByName(ROUTE_NAMES.PAYMENT_STATUS)
  url.searchParams.set(statusKey, statusType)
  url.searchParams.set('back_path', window.location.href.replace(window.location.origin, ''))

  return url
}

export class BillingService {
  async loadCompanyBillingInfo(companyId: Company['id'] | null) {
    const { data } = await api.billing.getCompanyBillingInfo({
      active_space_company_id: companyId,
    })

    if(data.user_id?.length) {
      billingStore.updatePersonalBilling(data)

      return
    }

    billingStore.updateBilling(data as Billing)
  }

  async reloadCurrentBilling() {
    const activeSpace = profilesStore.activeCompanyId

    await this.loadCompanyBillingInfo(activeSpace)
  }

  async loadActivePlans() {
    const { data } = await api.billing.getActiveTariffs()

    billingStore.activePlans = data
  }

  async topUpCreditsBalance(amount: number) {
    const successUrl = new URL(window.location.href)
    successUrl.searchParams.set(TOP_UP_STATUS_PARAM, 'success')

    const errorUrl = generateStatusUrl(TOP_UP_STATUS_PARAM, 'error')

    const response = await api.billing.topUpCredits({
      body: {
        credits_count: amount,
        success_url: String(successUrl),
        error_url: String(errorUrl),
      },
      params: {
        active_space_company_id: profilesStore.my_profile?.active_space_company_id ?? '',
      },
    })

    await this.handlePaymentResult(response, {})

    // window.location.href = response.headers.location
  }

  async upSeats(count: number, preferNewTab = false) {
    const successUrl = new URL(window.location.href)
    successUrl.searchParams.set(UP_SEATS_STATUS_PARAM, 'success')
    successUrl.searchParams.set(UP_SEATS_COUNT_STATUS_PARAM, String(count))

    const errorUrl = generateStatusUrl(UP_SEATS_STATUS_PARAM, 'error')

    const response = await api.billing.changeUserSeats({
      body: {
        up_active_users: count,
        success_url: String(successUrl),
        error_url: String(errorUrl),
      },
      params: {
        active_space_company_id: profilesStore.my_profile?.active_space_company_id ?? '',
      },
    })

    return new Promise(async resolve => {
      await this.handlePaymentResult(response, {
        preferNewTab,
        onRedirect: (location: string) => {
          if(location === successUrl.toString()) {
            return resolve(true)
          }
          if(location === errorUrl.toString()) {
            return resolve(false)
          }
          window.open(location, '_blank')
        },
      })

      resolve(null)
    })
  }

  async checkCurrentCompanyPermission(resource_name: BillingPermissionName) {
    const activeSpace = profilesStore.activeCompanyId ?? null

    try {
      if(!activeSpace) {
        await api.billing.checkPersonalPermission({ resource_name })
      } else {
        await api.billing.checkCompanyPermission({
          active_space_company_id: activeSpace,
          resource_name,
        })
      }

      return true
    } catch(e) {
      if(e?.response?.status === 402) {
        return false
      }

      throw e
    }
  }

  async changePlan(companyId: Company['id'], tariffPlanType: TariffPlanType, period: PaymentPeriods, preferNewTab = false) {
    const successUrl = new URL(getCurrentCleanUrl())
    successUrl.pathname = resolvePathByName(ROUTE_NAMES.SUBSCRIPTION_SUCCESS)
    successUrl.searchParams.set('company_id', companyId)

    const errorUrl = generateStatusUrl(SUBSCRIBE_STATUS_PARAM, 'error')
    errorUrl.searchParams.set('company_id', companyId)

    const response = await api.billing.changeTariffPlan({
      params: { active_space_company_id: companyId },
      body: {
        tariff_plan_type: tariffPlanType,
        tariff_plan_period: period,
        success_url: String(successUrl),
        error_url: String(errorUrl),
      },
    })

    this.handlePaymentResult(response, {
      preferNewTab,
    })
  }

  async cancelSubscription() {
    const activeCompanyId = profilesStore.my_profile?.active_space_company_id ?? null

    if(activeCompanyId) {
      const { data } = await api.billing.cancelSubscription({
        active_space_company_id: activeCompanyId,
      })

      billingStore.updateBilling(data)
    }
  }

  async addPaymentMethod() {
    const activeCompanyId = profilesStore.my_profile?.active_space_company_id ?? null

    const successUrl = generateStatusUrl(ADD_PAYMENT_METHOD_STATUS_PARAM, 'success')
    const errorUrl = generateStatusUrl(ADD_PAYMENT_METHOD_STATUS_PARAM, 'error')

    if(activeCompanyId) {
      const response = await api.billing.addPaymentMethod({
        params: { active_space_company_id: activeCompanyId },
        body: {
          success_url: String(successUrl),
          error_url: String(errorUrl),
        },
      })

      return this.handlePaymentResult(response, {})
    }
  }

  private async handlePaymentResult(resp: AxiosResponse, {
    onRedirect,
    preferNewTab,
  }: HandlePaymentResultParams) {
    bus.emit('billing/transactionCreated')
    // if(stripeSecret) {
    //   billingStore.stripePaymentIntentKey = stripeSecret
    //   billingStore.stripePaymentSuccessUrl = stripeHandleRedirect
    //   billingStore.stripeManualResultHandler = (location: string) => {
    //     if(onRedirect) {
    //       return onRedirect?.(location)
    //     }
    //   }
    //   // billingStore.stripeManualResultHandler = onRedirect || null
    //
    //   // if(stripeHandleRedirect) {
    //   //   const handleStripeSuccess = async () => {
    //   //     const response = await axios.get(stripeHandleRedirect)
    //   //
    //   //     response.headers.Location
    //   //
    //   //     billingStore.resetStripe()
    //   //
    //   //     if(onRedirect) {
    //   //       return onRedirect?.(stripeHandleRedirect)
    //   //     }
    //   //
    //   //     window.location.href = stripeHandleRedirect
    //   //   }
    //   //
    //   //   billingStore.stripeOnSuccess = handleStripeSuccess
    //   // }
    //
    //   return
    // }

    const location: string | null = await new Promise(resolve => {
      const stripeSecret = resp.headers['stripe-client-secret']
      const stripeAmount = resp.headers['stripe-amount']
      const stripeCurrency = resp.headers['stripe-currency']

      const stripeHandleRedirect = resp.headers['stripe-return-url']

      if(stripeSecret) {
        billingStore.stripePaymentIntentKey = stripeSecret
        billingStore.stripePaymentSuccessUrl = stripeHandleRedirect
        billingStore.stripePaymentAmount = stripeAmount
        billingStore.stripePaymentCurrency = stripeCurrency

        billingStore.stripeManualRedirectHandler = resolve
        billingStore.onStripeFinish = () => {
          console.log('seats finally str finish')
          resolve(null)
        }

        return
      }

      resolve(resp.headers.location)
    })

    if(location === null) {
      return null
    }

    if(location) {
      if(onRedirect) {
        return onRedirect?.(location)
      }

      if(preferNewTab) {
        window.open(location, '_blank')
      } else {
        window.location.href = location
      }

      return
    }

    toastError('Something went wrong')
  }

  async checkBillingPermission(name: BillingPermissionName, silent = false) {
    if(billingStore.billingError) {
      console.error('Billing error is already visible')

      return false
    }

    const res = await this.checkCurrentCompanyPermission(name)

    if(!res) {
      if(!silent && !billingStore.billingError) {
        billingStore.billingError = name
      }

      return false
      // throw new Error(`Billing permission error: ${name}`);
    }

    return true
  }
}

export const billingService = new BillingService()
