import data from '@roolz/icons/lottie/data/4cargo.json'
import Lottie, { LottieComponentProps } from 'lottie-react'

type Props = Omit<LottieComponentProps, 'animationData'>

export const Cargo2 = (props: Props) => {
  return (
    <Lottie
      animationData={data}
      {...props}
    />
  )
}

export default Cargo2
