import cn from 'classnames'
import { memo, useRef } from 'react'
import { BlockTitle } from '@roolz/sdk/components/ui/landings/BlockTitle/BlockTitle'
import { useGsap } from '@roolz/sdk/hooks/useGsap'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import { isClient } from '@roolz/sdk/utils/ssr'
import styles from './FAQ.module.scss'
import { Question } from './Question/Question'

interface Props {
  animate?: boolean

  theme?: 'light' | 'dark'

  customQuestions?: { title: string, description: string }[]

  i18n_namespace?: string
  i18n_prefix?: string
}

export const FAQ = memo((props: Props) => {
  const {
    theme = 'light',

    i18n_namespace,
    i18n_prefix,
  } = props

  const { t } = useSdkTranslation(i18n_namespace)

  return (
    <div
      className={cn(styles.root, {
        [styles.rootDark]: theme === 'dark',
      })}
    >
      <div className={styles.container}>
        <BlockTitle className={styles.title}>
          {t(`${i18n_prefix}.title`)}
        </BlockTitle>

        <FAQList {...props}/>
      </div>
    </div>
  )
})

export const FAQList = ({
  theme = 'light',

  animate = true,
  customQuestions,
  i18n_namespace,
  i18n_prefix,
}: Props) => {
  const { t } = useSdkTranslation(i18n_namespace)

  const questions = customQuestions || (t(`${i18n_prefix}.items`, { returnObjects: true }) || []) as unknown as { title: string, description: string }[]
  const ref = useRef<HTMLDivElement>(null)

  if(isClient()) {
    useGsap(gsap => {
      if(!animate) {
        gsap.fromTo('.faq-question', {
          translateX: -30,
          opacity: 0,
        }, {
          delay: 0.1,
          stagger: 0.15,
          duration: 0.4,

          translateX: 0,
          opacity: 1,
        })

        return
      }

      gsap.fromTo('.faq-question', {
        translateX: -30,
        opacity: 0,
      }, {
        scrollTrigger: {
          trigger: ref.current,
          start: 'top bottom-=5%',
        },

        delay: 0.1,
        stagger: 0.15,
        duration: 0.4,

        translateX: 0,
        opacity: 1,
      })
    })
  }

  return (
    <div
      itemScope
      itemType='https://schema.org/FAQPage'
      className={styles.list}
      ref={ref}
    >
      {Array.isArray(questions) && questions.map((item, i) => (
        <Question
          key={i}
          title={item.title}
          description={item.description}

          theme={theme}
          className='faq-question'
        />
      ))}
    </div>
  )
}

export default FAQ
