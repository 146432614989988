import { universalRoolzStaticUrl } from '@roolz/sdk/utils/urls'
import { get, set, remove } from './cookie'

const KEY = 'after_company_create_action'

export enum AfterCompanyCreateActions {
  showBilling = 'billing',
}

// @ts-ignore
const { hostname } = new URL(universalRoolzStaticUrl)
const domain = hostname.split('.').slice(-2).join('.')

export const setAfterCompanyCreateAction = (action: AfterCompanyCreateActions) => {
  set(KEY, action, { expires: '0', domain })
}

export const getAfterCompanyCreateAction = () => get(KEY) || null

export const removeAfterCompanyCreateAction = () => remove(KEY, { domain })
