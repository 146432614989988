import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import styles from './MostPopularLabel.module.scss'
import { memo, ReactNode } from 'react'
import cn from 'classnames'

export interface Props {
  className?: string
}

export const MostPopularLabel = memo(({
  className
}: Props) => {
  const { t } = useSdkTranslation('pricing')

  return (
    <div className={cn(styles.popularLabel, className)}>
      {t('plans.most_popular')}
    </div>
  )
})
