import { MapGeoRequest } from '@roolz/types/api/here-proxy'
import { LookupRequest, LookupResponse } from '@roolz/types/osm/nominatim'
import { AxiosInstance } from 'axios'
import { ApiClients, Config } from '@roolz/api/clients'

export class HereProxyApi {
  private config: Config
  private hereProxy: AxiosInstance

  constructor(clients: ApiClients) {
    this.config = clients.getConfig()
    this.hereProxy = clients.hereProxy
  }

  search(params: MapGeoRequest) {
    if(this.config.isRU()) {
      params = { isRU: true, ...params }
    }
    if(this.config?.activeSpaceCompanyId) {
      params.active_space_company_id = this.config.activeSpaceCompanyId()
    }

    return this.hereProxy.get('/mapgeo/v1/search', { params })
  }

  lookupById(params: LookupRequest) {
    if(this.config.isRU()) {
      params = { isRU: true, ...params }
    }

    return this.hereProxy.get<LookupResponse>('/osm/nominatim/lookup', { params,
      headers: {
        'X-Roolz-OsmDomain': 'http://nom.srv.roolz.net',
        'Accept-Language': ['ru', 'uk'].includes(this.config.language() ?? '')
          ? 'ru'
          : 'en',
      } })
  }

  notifySelection(osm_id: string) {
    return this.hereProxy.get<void>('/mapgeo/v1/search/selected', { params: { osm_id } })
  }
}
