import { TextField } from '@mui/material'
import { Loadable } from '@roolz/sdk/components'
import { ListItem } from '@roolz/sdk/components/companies/ui/Filters/Filters'
import { toastWarning } from '@roolz/sdk/components/snackbars'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { CloseButton } from '@roolz/sdk/components/ui/buttons/CloseButton/CloseButton'
import { Select } from '@roolz/sdk/components/ui/fields/Select/Select'
import { SelectType } from '@roolz/sdk/components/ui/SelectType/SelectType'
import { getFieldError, yupValidateFormValues } from '@roolz/sdk/utils/final-form'
import { Company } from '@roolz/types/api/companies'
import { CompanyFormSlug } from '@roolz/types/api/knowledge/companies'
import { createForm } from 'final-form'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { systemStore } from '@/store/system/system.store'
import { profilesStore } from '@/store/profiles/profiles.store'
import { companyKnowledgeStore } from '@/store/knowledge/stores/company-knowledge.store'
import { companiesService } from '@/store/companies/companies.service'
import { ShortCompanyForm, shortCompanySchema } from '@/schemas/companies/company.schema'
import { Label } from '@/components/ui/typography/Label/Label'
import { CountrySelect } from '@/components/ui/fields/CountrySelect/CountrySelect'
import { Modal, ModalProps } from '@/components/hoc/Modal'
import styles from './CreateCompanyShort.module.scss'

type Props = ModalProps & {
  onSuccess?: (company: Company) => void
}

const Content = observer(({
  setOpen,
  onSuccess,
}: Props) => {
  const { t: companyT } = useTranslation('company/create')
  const { t } = useTranslation('company/create')
  const { t: shortCreateT } = useTranslation('company/create_short')

  const [loading, setLoading] = useState(false)

  const userCountry = systemStore.userCountry

  const initialValues: ShortCompanyForm = {
    name: '',
    email: profilesStore.my_profile?.email ?? '',
    registration_country: userCountry,
    company_form: CompanyFormSlug.self_employed,
    exchange_role: null,
  }

  const onSubmit = async (values: ShortCompanyForm) => {
    console.log('submit', values)
    if(loading) {
      return
    }

    setLoading(true)

    try {
      const company = await companiesService.createCompany({
        goal: [],
        logo_path: null,
        name: values.name ?? '',
        exchange_role: values.exchange_role ?? '',
        company_form: values.company_form ?? '',
        fleet_amount: null,
        email: values.email,
        legal_form_short: '',
        legal_name: '',
        order_amount: '',
        registration_country_id: values.registration_country?.id ?? '',
        registration_number: '',
      })

      setOpen?.(false)
      onSuccess?.(company)
    } catch(e) {
      toastWarning(e?.response?.data?.error_msg ?? t('errors:insufficient_request'))
    }
    setLoading(false)
  }

  const [form] = useState(() => createForm<ShortCompanyForm>({
    initialValues,
    onSubmit,
    validate: yupValidateFormValues(yup.object(shortCompanySchema)),
  }))

  useEffect(() => {
    if(userCountry) {
      form.change('registration_country', userCountry)
    }
  }, [userCountry])

  const companyForms = useMemo(
    () => [
      CompanyFormSlug.self_employed,
      CompanyFormSlug.private_company,
    ].map(item => ({ value: item, title: shortCreateT(`form.company_forms.${item}`) })),
    [companyT],
  )

  const exchangeRoles: ListItem[] = useMemo(() => companyKnowledgeStore.supportedExchangeRoles.map(
    item => ({ value: item.slug, label: shortCreateT(`form.exchange_roles.${item.slug}`) }),
  ), [companyKnowledgeStore.supportedExchangeRoles])

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>
          {shortCreateT('title')}
        </div>

        <CloseButton
          className={styles.closeButton}
          onClick={() => setOpen?.(false)}
        />
      </div>
      <div className={styles.description}>
        {shortCreateT('description')}
      </div>

      <Form
        form={form}
        onSubmit={onSubmit}
        render={({ handleSubmit, touched, valid }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <Field
              name='name'
              render={({ input, meta }) => (
                <div className={styles.formGroup}>
                  <Label required>
                    {shortCreateT('form.name')}
                  </Label>
                  <TextField
                    fullWidth
                    placeholder={shortCreateT('form.name_placeholder')}
                    variant='outlined'
                    error={!!getFieldError(meta)}
                    helperText={getFieldError(meta) ?? ''}
                    {...input}
                  />
                </div>
              )}
            />

            <Field
              name='email'
              render={({ input, meta }) => (
                <div className={styles.formGroup}>
                  <Label required>
                    {shortCreateT('form.email')}
                  </Label>
                  <TextField
                    fullWidth
                    placeholder={shortCreateT('form.email_placeholder')}
                    variant='outlined'
                    error={!!getFieldError(meta)}
                    helperText={getFieldError(meta) ?? ''}
                    {...input}
                  />
                </div>
              )}
            />

            <Field
              name='registration_country'
              render={({ input, meta }) => (
                <div className={styles.formGroup}>
                  <Label required>
                    {shortCreateT('form.registration_country')}
                  </Label>
                  <CountrySelect
                    defaultValue={userCountry}
                    error={!!getFieldError(meta)}
                    helperText={getFieldError(meta)}
                    {...input}
                  />
                </div>
              )}
            />

            <Field
              name='company_form'
              render={({ input }) => (
                <div className={styles.formGroup}>
                  <Label required>
                    {shortCreateT('form.company_form')}
                  </Label>

                  <SelectType
                    itemClassName={styles.type__item}
                    items={companyForms}
                    {...input}
                  />
                </div>
              )}
            />

            <Field
              name='exchange_role'
              render={({ input, meta }) => (
                <div className={styles.formGroup}>
                  <Label required>
                    {shortCreateT('form.exchange_role')}
                  </Label>
                  <Select
                    id='exchange_role'
                    fullWidth
                    allowEmpty={false}
                    placeholder={shortCreateT('form.exchange_role_placeholder')}
                    items={exchangeRoles}
                    error={!!getFieldError(meta)}
                    helperText={getFieldError(meta)}
                    {...input}
                  />
                </div>
              )}
            />

            <div className={styles.actions}>
              <Button
                variant='text'
                onClick={() => setOpen?.(false)}
                type='button'
              >
                {shortCreateT('actions.cancel')}
              </Button>

              <Loadable loading={loading}>
                <Button
                  disabled={!touched || !valid}
                  variant='text'
                  type='submit'
                >
                  {shortCreateT('actions.submit')}
                </Button>
              </Loadable>
            </div>
          </form>
        )}
      />

    </div>
  )
})

export const CreateCompanyShort = Modal(Content, {
  classes: { paper: styles.paper },
})
