import dayjs from '@roolz/sdk/plugins/dayjs'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef } from 'react'
import { getNow } from '@/utils/date'
import { profilesStore } from '@/store/profiles/profiles.store'
import { billingService } from '@/store/billing/billing.service'
import { billingStore } from '@/store/billing/billing.store'

const ADDITIONAL_TIME = 1000

export const BillingActualizer = observer(() => {
  const billing = billingStore.billing

  const activeSpace = profilesStore.activeCompanyId
  const reloadBilling = () => activeSpace && billingService.loadCompanyBillingInfo(activeSpace)

  const getDiffForDatetime = (datetime: number) => datetime - Number(getNow())

  const expirationTimer = useRef(0)
  useEffect(() => {
    clearTimeout(expirationTimer.current)

    const expirationAt = billing.expiration_time

    if(expirationAt) {
      const diff = getDiffForDatetime(new Date(expirationAt).getTime())

      console.log('diff 1', diff)

      expirationTimer.current = setTimeout(reloadBilling, diff + ADDITIONAL_TIME) as any
    }
  }, [billing.expiration_time])

  const nextStateTimer = useRef(0)
  useEffect(() => {
    clearTimeout(nextStateTimer.current)

    const nextStateAt = billing.billing_next_state_time * 1000

    if(nextStateAt) {
      const diff = getDiffForDatetime(nextStateAt)

      console.log('diff 2', nextStateAt, Number(getNow()), diff)

      nextStateTimer.current = setTimeout(reloadBilling, diff + ADDITIONAL_TIME) as any
    }
  }, [billing.billing_next_state_time])

  return null
})
