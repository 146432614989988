import { useGsap } from '@roolz/sdk/hooks/useGsap'
import cn from 'classnames'
import { FC, memo, useRef } from 'react'
import styles from './BlockTitle.module.scss'

interface Props {
  Tag?: string | FC<any>
  children: string

  animated?: boolean

  className?: string
}

export const BlockTitle = memo(({
  className,
  children,
  Tag = 'h2',
  animated = true
}: Props) => {
  const wordsRef = useRef<(HTMLSpanElement | null)[]>([])
  const ref = useRef<HTMLHeadingElement | HTMLElement>(null)

  useGsap(gsap => {
    gsap.from(wordsRef.current, {
      scrollTrigger: {
        trigger: ref.current,

        start: 'top bottom',
        // end: "-=20%",
        toggleActions: 'restart none none none'

        // start: "top bottom", //will trigger if the top of the elements hits 75% of the viewport from top
        // endTrigger: "html", // uncomment if you want to repeat class toggling
        // end: "bottom top", // change to "bottom center" and comment out endTrigger if you want to repeat animation
      },
      opacity: 0,

      delay: 0.2
      // duration: 0.3,
      // ease: 'cubic-bezier(.5,0,0,1)',
      // translateY: 80,
      //
      // stagger: 0.1
    })
  })

  return (
    <Tag
      className={cn(styles.root, className)}
      ref={ref}
    >
      {children}
      {/*{children.split(' ').map((word, i) => (*/}
      {/*  <span*/}
      {/*    className={styles.word}*/}
      {/*    ref={el => wordsRef.current[i] = el}*/}
      {/*  >{word + ' '}</span>*/}
      {/*))}*/}
    </Tag>
  )
})
