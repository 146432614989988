import { BaseChangeEvent } from '@roolz/types/custom'
import {
  ChangeEvent,
  ClipboardEvent,
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react'

/**
 * TIP: if using react hook form and mui TextField, required to wrap TextField using react-hook-form Controller, to make field controlled
 */
interface Props {
  value?: string
  onChange: (e: BaseChangeEvent) => void
  /**
   * Function that should get value and return if is valid
   * @param value
   */
  // validator: (value: string) => boolean

  /**
   * Function that receives input and returns transformed to allowed characters string
   * Also paste content will be handled by it
   * @param value
   */
  transformer: (value: string) => string
  blurTransformer?: (value: string) => string
  /**
   * Callback that cleans pasting to input content vie ctrl + v, etc
   * Should return only transformed clipboard part, not whole value cause will be concatenated with other parts of current value
   *
   * If not passed, transformer will be used
   * @param content
   */
  pasteContentCleaner?: (pasteContent: string, fullValue: string) => string

  [key: string]: any
}

export const RestrictedInput = forwardRef(({
  value,
  onChange,
  // validator,
  transformer,
  blurTransformer,
  pasteContentCleaner,
  ...rest
}: Props, forwardedRef: any) => {
  const [currentValue, setCurrentValue] = useState<string>(value ?? '')
  const [[selectionStart, selectionEnd], setSelection] = useState<[number, number]>([0, 0])

  // Forwarded ref passed by MUI just doesn't work!
  // Its .current is always undefined, so have to create own ref
  const fallbackRef = useRef<any>(null)
  const ref = fallbackRef
  const isFocus = useRef(false)

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const prepared = transformer(e.target.value)

    if(prepared !== currentValue) {
      setCurrentValue(prepared)
      onChange({
        target: { name: rest?.name ?? undefined, value: prepared }
      })
    }

    setSelection([ref.current.selectionStart, ref.current.selectionEnd])
  }, [currentValue])

  const handleBlur = useCallback((e: any) => {
    isFocus.current = false

    if(blurTransformer) {
      setCurrentValue(blurTransformer(currentValue))
    }
    if(rest.onBlur) {
      rest.onBlur(e)
    }
  }, [currentValue])

  const handleFocus = useCallback((e: any) => {
    isFocus.current = true
    if(rest.onFocus) {
      rest.onFocus(e)
    }
  }, [currentValue])

  const handlePaste = useCallback((e: ClipboardEvent<HTMLInputElement>) => {
    const selectionStart = ref.current.selectionStart
    const selectionEnd = ref.current.selectionEnd

    const clipboardContent = e.clipboardData.getData('text/plain')
    const fullValue = currentValue.substring(0, selectionStart)
      + clipboardContent
      + currentValue.substring(selectionEnd)

    if(pasteContentCleaner) {
      const cleanedClipboardContent = pasteContentCleaner(clipboardContent, fullValue)

      const newVal = currentValue.substring(0, selectionStart)
        + cleanedClipboardContent
        + currentValue.substring(selectionEnd)

      if(newVal === currentValue) {
        setCurrentValue(newVal)
        onChange({
          target: { name: rest?.name ?? undefined, value: newVal }
        })
      }

      setSelection([
        selectionStart + cleanedClipboardContent.length,
        selectionStart + cleanedClipboardContent.length
      ])

      e.preventDefault()
    }
    // else {
    //   let newValue = transformer(fullValue)
    //
    //   setCurrentValue(newValue)
    //   setSelection([
    //     newValue.length,
    //     newValue.length,
    //   ])
    // }
  }, [currentValue])

  useLayoutEffect(() => {
    if(isFocus.current) {
      ref.current.selectionStart = selectionStart
      ref.current.selectionEnd = selectionEnd
    }
  },  [currentValue, selectionStart, selectionEnd])

  useEffect(() => {
    setCurrentValue(value ?? '')
  }, [value])

  return (
    <input
      {...rest}
      ref={ref}
      value={currentValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onPaste={handlePaste}
    />
  )
})
