import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { CloseButton } from '@roolz/sdk/components/ui/buttons/CloseButton/CloseButton'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog } from '@/components/ui/Dialog/Dialog'
import { Modal } from '@/components/hoc/Modal'
import styles from './NoPermissionError.module.scss'

type Props = {
  open: boolean
  setOpen: (open: boolean) => any
}

export const NoPermissionError = Modal(observer((props: Props) => {
  const {
    open,
    setOpen,
  } = props

  const { t } = useTranslation('modals', {
    useSuspense: false,
    keyPrefix: 'no_permission',
  })

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth='xs'
      classes={{ paper: styles.paper }}
      scroll='body'
    >
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.header}>
            <CloseButton
              className={styles.close}
              onClick={() => setOpen?.(false)}
            />
          </div>
          <div className={styles.title}>
            {t('title')}
          </div>
          <div className={styles.description}>
            {t('description')}
          </div>

          <div className={styles.appeal}>
            {t('appeal')}
          </div>
        </div>
        <Button
          className={styles.accept}
          onClick={() => setOpen?.(false)}
        >
          {t('confirm')}
        </Button>
      </div>
    </Dialog>
  )
}))
