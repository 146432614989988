import { useCallback, useEffect, useRef, useState } from 'react'

const subs: Record<string, Array<(val: unknown) => void>> = {}
const values: Record<string, unknown> = {}

export const useSyncedState = <T>(syncKey: string, defaultValue?: T) => {
  const [val, setVal] = useState<T>((values[syncKey] as T) ?? defaultValue as T)

  useEffect(() => {
    subs[syncKey] ??= []

    subs[syncKey].push(setVal)

    return () => {
      subs[syncKey] = subs[syncKey].filter(sub => sub !== setVal)

      if(!subs[syncKey].length) {
        delete subs[syncKey]
        delete values[syncKey]
      }
    }
  }, [])

  const setState = useCallback((val: T) => {
    values[syncKey] = val

    setVal(val)
    subs[syncKey]?.forEach(sub => sub(val))
  }, [])

  return [val, setState] as [T, (val: T) => void]
}
