import data from '@roolz/icons/lottie/data/zavod.json'
import Lottie, { LottieComponentProps } from 'lottie-react'

type Props = Omit<LottieComponentProps, 'animationData'>

export const Zavod = (props: Props) => {
  return (
    <Lottie
      animationData={data}
      {...props}
    />
  )
}

export default Zavod
