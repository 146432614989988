import Lottie, { LottieComponentProps } from 'lottie-react'
import data from './data/dangerous.json'

type Props = Omit<LottieComponentProps, 'animationData'>

export const Dangerous = (props: Props) => (
  <Lottie
    animationData={data}
    {...props}
  />
)

export default Dangerous
