import { memo, ReactNode } from 'react'
import cn from 'classnames'
import { useSdkTranslation } from '@roolz/sdk/SdkContext'
import { universalRoolzStaticUrl } from '@roolz/sdk/utils/urls'
import styles from './MostValuableLabel.module.scss'

export interface Props {
  className?: string
}

export const MostValuableLabel = memo(({
  className,
}: Props) => {
  const { t } = useSdkTranslation('pricing')

  return (
    <div className={cn(styles.valuableLabel, className)}>
      {t('plans.most_valuable')}

      <img
        className={styles.valuableLabel__img}
        src={`${universalRoolzStaticUrl}/images/pricing/crown.png`}
        alt=''
      />
    </div>
  )
})
