import ArrowOutward from '@material-symbols/svg-400/rounded/arrow_outward.svg'
import { Fade } from '@mui/material'
import PricingAppeal from '@roolz/icons/lottie/PricingAppeal'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import { useTranslation } from 'react-i18next'
import styles from '@/components/company-admin/pricing/ui/PremiumAppealButton/PremiumAppealButton.module.scss'
import { memo, ReactNode, useEffect, useState } from 'react'
import BackgroundImage from '@/assets/images/premium_button_background.png'
import cn from 'classnames'

interface Props {
  className?: string
  onClick: () => void
}

enum NameVariants {
  Abilities = 'abilities',
  Business = 'business',
  Logistics = 'logistics'
}

export const PremiumAppealButton = memo(({
  className,
  onClick
}: Props) => {
  const { t } = useTranslation('ui')

  const [currentVariant, setCurrentVariant] = useState(0)
  const [animStarted, setAnimStarted] = useState(false)

  const variants = [
    {
      name: NameVariants.Abilities,
      duration: 1000
    },
    {
      name: NameVariants.Business,
      duration: 2000
    },
    {
      name: NameVariants.Logistics,
      duration: 5500
    },
    {
      name: NameVariants.Abilities,
      duration: 1000
    }
  ]

  const namePostfix = variants[currentVariant].name

  useEffect(() => {
    if(!animStarted) {
      return
    }

    const timer = setTimeout(() => {
      setCurrentVariant((prevVariant) => (prevVariant + 1) % (variants.length))
    }, variants[currentVariant].duration)

    return () => clearTimeout(timer)
  }, [animStarted, currentVariant])

  return (
    <button
      style={{
        backgroundImage: `url(${BackgroundImage})`
      }}
      className={cn(styles.root, className)}
      onClick={onClick}
    >
      <div className={styles.leftIcon}>
        <PricingAppeal onDOMLoaded={() => setAnimStarted(true)}/>
      </div>

      <div className={styles.content}>
        {t('premium_button.expand')}
        <Fade
          key={variants[currentVariant].name}
          timeout={700}
          appear
          in
        >
          <div className={styles.content__highlight}>
            {t('premium_button.variants.' + namePostfix)}
          </div>
        </Fade>
      </div>

      <div className={styles.rightIcon}>
        <MaterialSymbolIcon
          className={styles.rightIcon__arrow}
          size={16}
          icon={<ArrowOutward/>}
        />
      </div>
    </button>
  )
})
