import { RestrictedInput } from '@roolz/sdk/components/ui/fields/RestrictedInput/RestrictedInput'
import { BaseChangeEvent } from '@roolz/types/custom'
import { forwardRef } from 'react'

interface ChangeEvent {
  target: {
    name: string
    value: string | null
  }
}

interface Props {
  /*
   Integers only
   */
  integer?: boolean
  /*
   Positive only
   */
  positive?: boolean

  // min?: number
  // max?: number

  maxIntegerLength?: number
  maxPrecision?: number

  onChange: (event: ChangeEvent) => void
  value: number | null

  [key: string]: any
}

export const NumericInput = forwardRef(({
  integer = false,
  positive = false,

  // min,
  // max,
  maxIntegerLength,

  maxPrecision,

  onChange,
  value = null,
  ...rest
}: Props, ref: any) => {
  // const applyMinMaxBounds = (value: string) => {
  //   let number = Number.parseFloat(value)
  //
  //   if(Number.isNaN(number)) {
  //     return value
  //   }
  //
  //   if(min !== undefined && number < min) {
  //     number = min
  //   } else if(max !== undefined && number > max) {
  //     number = max
  //   }
  //   return String(number)
  // }

  const applyIntegerPartBounds = (value: string) => {
    if(maxIntegerLength !== undefined) {

      const integerNumber = Number.parseInt(value)
      if(Number.isNaN(integerNumber)) {
        return value
      }

      const integerString = String(integerNumber)

      if(integerString.length > maxIntegerLength) {
        const parts = value.split('.')

        parts[0] = parts[0].substring(0, maxIntegerLength)

        return parts.join('.')
      }

    }
    return value
  }

  const applyPrecisionBounds = (value: string) => {
    if(maxPrecision !== undefined && value.includes('.')) {
      const currentPrecision = value.replace(/.*\./, '').length

      if(currentPrecision > maxPrecision) {
        value = value.replace(new RegExp('\\d' + `{${currentPrecision - maxPrecision}}` + '$'), '')
      }
    }
    return value
  }

  function transformer(value: string): string {
    value = value.replace(/^[^0-9-]+/, '')

    // need this only while component returns string
    // value = value.replace(/^(-?)0+/, '$1')

    if(positive) {
      // remove all -
      value = value.replaceAll('-', '')
    } else {
      // remove only extra - that not at the start
      value = value.replaceAll(/(.+)-/g, '$1')
        .replaceAll(/^-[^0-9]/g, '-')
        // .replace('/-$/', '')
    }

    value = applyIntegerPartBounds(value)
    value = applyPrecisionBounds(value)

    if(integer) {
      value = value.replaceAll(/[^0-9-]/g, '')
    } else {
      value = value
        .replaceAll(',', '.')
        .replaceAll(/([^.]*\.[^.]*)\./g, '$1')
        .replaceAll(/[^0-9\-.]/g, '')
    }

    return value
  }

  function blurTransformer(value: string): string {
    // if it float, remove all zeros from the end
    if(/\./.test(value)) {
      value = value.replaceAll(/0+$/g, '')
    }

    value = value
      .replace(/^(-?)0+([1-9]+)/, '$1$2')
      .replace(/\.+$/, '')

    // value = applyMinMaxBounds(value)
    value = applyIntegerPartBounds(value)
    value = applyPrecisionBounds(value)

    return value
  }

  function handleChange(event: BaseChangeEvent): void {
    let value = event.target.value
    // value = applyMinMaxBounds(value)

    // let num
    //
    // if(integer) {
    //   num = Number.parseInt(value)
    // } else {
    //   num = Number.parseFloat(value)
    // }
    //
    // num = Number.isNaN(num) ? null : num

    onChange({
      target: {
        name: event.target.name,
        value: value?.length ? value : null
      }
    })
  }

  return (
    <RestrictedInput
      ref={ref}
      onChange={handleChange}
      transformer={transformer}
      value={transformer(String(value))}
      blurTransformer={blurTransformer}
      {...rest}
    />
  )
})
