import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Loadable } from '@roolz/sdk/components'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ReactNode, useCallback, useEffect } from 'react'

export interface ConfirmationProps {
  open: boolean
  setOpen: (open: boolean) => any

  title?: string | ReactNode
  content?: ReactNode

  actions: ReactNode | ((props: { loading: boolean }) => ReactNode)
  maxWidth?: number

  loading?: boolean

  actionsClassName?: string

  onConfirm?: () => void
  onClose?: () => void
}

export const Confirmation = observer(({
  open,
  setOpen,
  title,
  content,
  actions,
  maxWidth = 312,
  loading,

  onConfirm,
  onClose,
  actionsClassName
}: ConfirmationProps) => {
  const handleKeydown = useCallback((e: KeyboardEvent) => {
    if(e.key === 'Enter') {
      onConfirm?.()
    }
  }, [onConfirm])

  useEffect(() => {
    if(open) {
      window.addEventListener('keydown', handleKeydown)
    } else {
      window.removeEventListener('keydown', handleKeydown)
    }

    return () => window.removeEventListener('keydown', handleKeydown)
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={() => {
        if(loading) return

        setOpen(false)
        onClose?.()
      }}
      maxWidth='xs'
      sx={{ borderRadius: 0 }}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent style={{ maxWidth }}>
        {content}
      </DialogContent>
      <Loadable loading={!!loading}>
        <DialogActions>
          {typeof actions === 'function'
            ? actions({ loading: !!loading })
            : actions}
        </DialogActions>
      </Loadable>

    </Dialog>
  )
})
