import { useCallback, useState } from 'react'

export const useModalControl = (defaultValue?: boolean) => {
  const [open, setOpen] = useState(defaultValue ?? false)

  const show = useCallback(() => setOpen(true), [])
  const close = useCallback(() => setOpen(false), [])

  return {
    state: {
      open,
      setOpen,
    },

    show,
    close
  }
}
