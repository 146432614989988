import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import arraySupport from 'dayjs/plugin/arraySupport'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isYesterday from 'dayjs/plugin/isYesterday'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import localeData from 'dayjs/plugin/localeData'
import customParseFormat from 'dayjs/plugin/customParseFormat';
import calendar from 'dayjs/plugin/calendar'
import duration from 'dayjs/plugin/duration'

import 'dayjs/locale/en'
import 'dayjs/locale/ru'
import 'dayjs/locale/zh'
import 'dayjs/locale/fr'
import 'dayjs/locale/de'
import 'dayjs/locale/hi'
import 'dayjs/locale/pl'
import 'dayjs/locale/es'
import 'dayjs/locale/tr'
import 'dayjs/locale/uk'

dayjs.extend(LocalizedFormat)
dayjs.extend(localeData)
dayjs.extend(customParseFormat);
dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(arraySupport)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(isYesterday)
dayjs.extend(isSameOrBefore)
dayjs.extend(duration)
dayjs.extend(calendar)

export default dayjs
