import { Fade, Portal } from '@mui/material';
import { Loadable } from '@roolz/sdk/components';
import cn from 'classnames';
import styles from './LoadingOverlay.module.scss';

export interface Props {
  open: boolean;
  fixed?: boolean;
}

export const LoadingOverlay = ({
  open,
  fixed = false
}: Props) => {
  return (
    <Portal>
      <Fade
        in={open}
        unmountOnExit
      >
        <div
          className={cn(styles.overlay, {
            [styles.overlayFixed]: fixed
          })}
        >
          <Loadable loading/>
        </div>
      </Fade>
    </Portal>
  );
};
