import { toastError, toastSuccess } from '@roolz/sdk/components/snackbars'
import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import {
  ADD_PAYMENT_METHOD_STATUS_PARAM,
  SUBSCRIBE_STATUS_PARAM,
  TOP_UP_STATUS_PARAM, UP_SEATS_COUNT_STATUS_PARAM, UP_SEATS_STATUS_PARAM,
} from '@/store/billing/billing.service'

export const PricingNotificationsController = memo(() => {
  const { t } = useTranslation('company/pricing', { useSuspense: false })
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()

  useEffect(() => {
    if(searchParams.has('company_id') || searchParams.has('back_path')) {
      console.log('WAITING FOR MAKE NECESSARY REDIRECTS BEFORE DISPLAYING PAYMENT STATUS MESSAGES')

      return
    }

    const upSeatsStatus = searchParams.get(UP_SEATS_STATUS_PARAM)
    const upSeatsCount = searchParams.get(UP_SEATS_COUNT_STATUS_PARAM)

    if(upSeatsStatus === 'success') {
      toastSuccess(t('up_seats.success', {
        seatsCount: upSeatsCount,
      }))
    } else if(upSeatsStatus === 'error') {
      toastError(t('up_seats.error'))
    }

    const topUpStatus = searchParams.get(TOP_UP_STATUS_PARAM)

    if(topUpStatus === 'success') {
      toastSuccess(t('top_up.success'))
    } else if(topUpStatus === 'error') {
      toastError(t('top_up.error'))
    }

    const addPaymentMethodStatus = searchParams.get(ADD_PAYMENT_METHOD_STATUS_PARAM)
    if(addPaymentMethodStatus === 'success') {
      toastSuccess(t('payment_methods.add_method_result.success'))
    } else if(addPaymentMethodStatus === 'error') {
      toastError(t('payment_methods.add_method_result.error'))
    }

    const subscribeStatus = searchParams.get(SUBSCRIBE_STATUS_PARAM)
    if(subscribeStatus === 'error') {
      toastError(t('subscribe_error'))
    }

    searchParams.delete(TOP_UP_STATUS_PARAM)
    searchParams.delete(ADD_PAYMENT_METHOD_STATUS_PARAM)
    searchParams.delete(SUBSCRIBE_STATUS_PARAM)
    searchParams.delete(UP_SEATS_STATUS_PARAM)
    searchParams.delete(UP_SEATS_COUNT_STATUS_PARAM)

    // history.replaceState(location.state, '', location.pathname + (searchParams.size ? ('?' + searchParams.toString()) : ''))

    if(topUpStatus || addPaymentMethodStatus || subscribeStatus || upSeatsStatus || upSeatsCount) {
      console.log('location.state', location)

      setSearchParams(searchParams, {
        state: location.state,
        replace: true,
        preventScrollReset: true,
      })
    }
  }, [searchParams, setSearchParams, t])

  return null
})
