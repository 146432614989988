import Lottie, { LottieComponentProps } from 'lottie-react'
import data from './data/men.json'

type Props = Omit<LottieComponentProps, 'animationData'>

export const Men = (props: Props) => (
  <Lottie
    animationData={data}
    {...props}
  />
)

export default Men
