import { companiesStore } from '@/store/companies/companies.store';
import { getDocLinks } from '@roolz/sdk/utils/docs';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styles from './Docs.module.scss';
import cn from 'classnames'

interface Props {
  className?: string
}

export const Docs = observer(({
  className
}: Props) => {
  const { t } = useTranslation('shared')
  const userCountryAlpha2 = (companiesStore.activeCompany?.registration_country?.alpha2 ?? '')

  const links = getDocLinks(userCountryAlpha2)

  return (
    <div className={cn(styles.root, className)}>
      {Object.entries(links).map(([type, url]) => (
        <a
          href={url}
          className={styles.link}
          target='_blank'
        >
          {t(`docs.${type}`)}
        </a>
      ))}
    </div>
  );
});
