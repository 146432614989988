import Lottie, { LottieComponentProps } from 'lottie-react'
import data from './data/warning_16.json'

type Props = Omit<LottieComponentProps, 'animationData'>

export const Warning1 = (props: Props) => (
  <Lottie
    animationData={data}
    {...props}
  />
)
