import PersonAdd from '@material-symbols/svg-400/rounded/person_add.svg'
import Men from '@roolz/icons/lottie/Men'
import { MaterialSymbolIcon } from '@roolz/icons/MaterialSymbolIcon'
import { Loadable } from '@roolz/sdk/components'
import { toastError, toastWarning } from '@roolz/sdk/components/snackbars'
import Button from '@roolz/sdk/components/ui/buttons/Button/Button'
import { CloseButton } from '@roolz/sdk/components/ui/buttons/CloseButton/CloseButton'
import { usePrevious } from '@roolz/sdk/hooks/helpers/usePrevious'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { billingService } from '@/store/billing/billing.service'
import { Dialog } from '@/components/ui/Dialog/Dialog'
import { Modal } from '@/components/hoc/Modal'
import styles from './AddUsersLimitReached.module.scss'

type Props = {
  open: boolean
  setOpen: (open: boolean) => any

  onSuccess?: () => void
  onError?: () => void

  seatsCount: number
}

export const AddUsersLimitReached = Modal(observer((props: Props) => {
  const {
    open,
    setOpen,
    seatsCount,

    onSuccess,
    onError,
  } = props

  const prevSeats = usePrevious(seatsCount)

  const { t: pricingT } = useTranslation('company/pricing', { useSuspense: false })

  const { t } = useTranslation('modals', {
    keyPrefix: 'max_seats_count_reached',
  })

  const [loading, setLoading] = useState(false)

  const handleConfirm = async () => {
    if(loading) return

    setLoading(true)

    try {
      const result = await billingService.upSeats(seatsCount, true)
      console.log('seats finally 1')

      if(result === true) {
        await onSuccess?.()
      }
      if(result === false) {
        if(onError) {
          await onError?.()
        } else {
          toastError(pricingT('up_seats.error'))
        }
      }

      setOpen?.(false)
    } catch(e) {
      toastWarning(t('errors:insufficient_request'))
    } finally {
      console.log('SEATS FINALLY')
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth='xs'
      classes={{ paper: styles.paper }}
      scroll='body'
    >
      <div className={styles.root}>
        <div className={styles.content}>
          <div className={styles.header}>
            <Men className={styles.icon}/>

            <CloseButton
              className={styles.close}
              onClick={() => setOpen?.(false)}
            />
          </div>
          <div className={styles.title}>
            {t('title')}
          </div>
          <div className={styles.description}>
            {t('description')}
          </div>
          <div className={styles.seats}>
            <MaterialSymbolIcon
              size={22}
              icon={<PersonAdd/>}
              className={styles.seats__icon}
            />

            {t('users', { count: seatsCount || prevSeats })}
          </div>
        </div>
        <Loadable loading={loading}>
          <Button
            className={styles.accept}
            onClick={handleConfirm}
          >
            {t('confirm')}
          </Button>
        </Loadable>
      </div>
    </Dialog>
  )
}))
