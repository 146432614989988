import { AxiosInstance } from 'axios'
import { ApiClients } from '@roolz/api/clients'

export class StaticApi {
  client: AxiosInstance

  constructor(clients: ApiClients) {
    this.client = clients.static
  }

  getCompanyCategories() {
    return this.client.get('/api/company/categories')
  }

  getCompanyCategoryFilters() {
    return this.client.get('/api/company/filters')
  }

  getSignupExcludeConfig() {
    return this.client.get('/api/auth/config')
  }
}
