import {
  ExtraOfferStatus,
  GetMyOffersBody,
  GetMyOffersParams,
  Offer,
  OfferInStatusCount,
  OfferStatus,
} from '@roolz/types/api/exchange'
import { makeAutoObservable, toJS } from 'mobx'
import dayjs from '@roolz/sdk/plugins/dayjs'
import { getCurrentFilter } from '@/pages/home/offers/MyOffers/utils/getCurrentFilter'
import { exchangeService } from '@/store/exchange/exchange.service'
import { exchangeStore } from '@/store/exchange/exchange.store'
import { profilesStore } from '@/store/profiles/profiles.store'

export const ITEMS_PER_PAGE = 20

export const iterableStatusMap = {
  [ExtraOfferStatus.OPENED]: [OfferStatus.PUBLISHED, OfferStatus.NOT_PUBLISHED, OfferStatus.AWAIT_CONFIRM, OfferStatus.CONFIRMATION],
  [OfferStatus.NOT_PUBLISHED]: [OfferStatus.NOT_PUBLISHED],
  [OfferStatus.PUBLISHED]: [OfferStatus.PUBLISHED],
  [OfferStatus.AWAIT_CONFIRM]: [OfferStatus.AWAIT_CONFIRM],
  [OfferStatus.CONFIRMATION]: [OfferStatus.CONFIRMATION],
  [OfferStatus.CLOSED]: [OfferStatus.DEAL_MADE, OfferStatus.CLOSED],
}

export const myOffersStoreStatuses = (Object.keys(iterableStatusMap) as unknown as (keyof typeof iterableStatusMap)[])

export class MyOffersStore {
  [ExtraOfferStatus.OPENED]: OffersStatusStore
  [OfferStatus.NOT_PUBLISHED]: OffersStatusStore
  [OfferStatus.PUBLISHED]: OffersStatusStore
  [OfferStatus.AWAIT_CONFIRM]: OffersStatusStore
  [OfferStatus.CONFIRMATION]: OffersStatusStore
  [OfferStatus.CLOSED]: OffersStatusStore

  constructor() {
    makeAutoObservable(this)

    myOffersStoreStatuses.forEach(status => this[status] = new OffersStatusStore(this, iterableStatusMap[status]))
  }

  get isEmptyOpenedOffers() {
    return (
      !this[OfferStatus.PUBLISHED].total
      && !this[OfferStatus.NOT_PUBLISHED].total
      && !this[OfferStatus.CONFIRMATION].total
      && !this[OfferStatus.AWAIT_CONFIRM].total
    )
  }

  addOfferByStatus = (offer: Offer) => {
    myOffersStoreStatuses
      .filter(status => iterableStatusMap[status].includes(offer.status))
      .forEach(status => this[status].addOffer(offer._id))
  }

  removeOfferByPrevStatus = (offer: Offer) => {
    myOffersStoreStatuses
      .filter(status => (offer.prev_status ? iterableStatusMap[status].includes(offer.prev_status) : false))
      .forEach(status => this[status].removeOffer(offer._id))
  }

  updateOffer(offer: Offer) {
    if(!profilesStore.hasAccessToOffer(offer)) return

    this.removeOfferByPrevStatus(offer)
    this.addOfferByStatus(offer)
  }

  reset(withTotal = true) {
    myOffersStoreStatuses.forEach(status => this[status].reset(withTotal))
  }
}

export enum SortOffersBy {
  UPDATED_DATE = 'updated_at',
  CREATED_DATE = 'created_at',
}

const isStatusExist = (offerStatus: OfferStatus | null, totalStatus: OfferStatus) => {
  if (!offerStatus) return false

  const actualStatus = offerStatus === OfferStatus.DEAL_MADE ? OfferStatus.CLOSED : offerStatus

  return actualStatus === totalStatus
}

export class OffersStatusStore {
  isLoadedOnce = false
  reachedEnd = false
  stateId = Math.random()
  lastReqData: any = {}

  total = 0
  offers: Offer['_id'][] = []
  loading = false
  totalByStatus: OfferInStatusCount[] = []

  sortBy: SortOffersBy = SortOffersBy.UPDATED_DATE
  statuses: OfferStatus[]
  rootStore: MyOffersStore

  constructor(rootStore: MyOffersStore, statuses: OfferStatus[]) {
    makeAutoObservable(this)

    this.rootStore = rootStore
    this.statuses = statuses
  }

  get items() {
    return this.offers
      .map(exchangeStore.findOffer)
      .filter((offer): offer is Offer => !!offer)
      .filter(offer => !offer.deleted_at)
      .sort((a, b) => dayjs(b.updated_at).unix() - dayjs(a.updated_at).unix())
  }

  get itemsLeft() {
    if(this.reachedEnd) {
      return 0
    }

    return Math.min(ITEMS_PER_PAGE, this.total - this.offers.length)
  }

  get isEmptyOpenedOffers() {
    return this.totalByStatus.every(item => !item.count)
  }

  addOffer = (id: Offer['_id']) => {
    if(this.offers.includes(id)) return

    this.total += 1
    this.offers.unshift(id)
  }

  addOfferList = (offers: Offer[]) => {
    offers.forEach(({ _id }) => this.addOffer(_id))
  }

  updateTotalByOffer = (offer: Offer | Offer[], incrementOnly = false) => {
    const offerList = Array.isArray(offer) ? offer : [offer]

    this.totalByStatus.forEach(item => {
      if(!incrementOnly) {
        const prevStatusExistOffers = offerList
          .filter(({ status, prev_status }) => status !== prev_status
            && isStatusExist(prev_status, item.status))

        item.count -= prevStatusExistOffers.length
      }

      const newStatusExistOffers = offerList
        .filter(({ status }) => isStatusExist(status, item.status))

      item.count += newStatusExistOffers.length
    })
  }

  removeOffer = (id: Offer['_id']) => {
    this.total -= 1
    this.offers = this.offers.filter(_id => _id !== id)
  }

  async loadWithLastParams() {
    const { params, body } = this.lastReqData || {}

    const limit = ITEMS_PER_PAGE > this.offers.length ? ITEMS_PER_PAGE : this.offers.length

    this.loadMore({ ...params, limit }, body)
  }

  async loadMore(params?: GetMyOffersParams, body?: GetMyOffersBody) {
    this.loading = true

    if (params?.offset === 0) {
      this.offers = []
      this.isLoadedOnce = false
      this.reachedEnd = false
    }

    const currentStateId = this.stateId
    const offset = this.offers.length

    if(this.isLoadedOnce && offset >= this.total) {
      console.groupCollapsed('%c Trying to load offers exceeding total count', 'color: orange')
      console.log('store statuses: ', toJS(this.statuses))
      console.log('total: ', this.total)
      console.log('offset: ', offset)
      console.groupEnd()
    }

    try {
      const reqPrams = {
        limit: ITEMS_PER_PAGE,
        status: this.statuses,
        ...params,
        offset: params?.offset || offset,
      }

      if (offset === 0) this.lastReqData = { params: reqPrams, body }

      const { result, count_by_status, total } = await exchangeService.loadMyOffers(reqPrams, body)

      console.log('SET LAST REQ', { params: reqPrams, body })

      this.isLoadedOnce = true
      this.total = total
      this.offers = [...new Set([...this.offers, ...result.map(item => item._id)])]
      this.totalByStatus = count_by_status

      if(result.length < ITEMS_PER_PAGE) this.reachedEnd = true

      this.loading = false
    } catch(e) {
      if(this.stateId !== currentStateId) {
        console.log('Offers loading result is stale')

        return
      }
      this.loading = false

      throw e
    }
  }

  reset(withTotal = true) {
    this.isLoadedOnce = false
    this.reachedEnd = false
    withTotal && (this.total = 0)
    this.offers = []
    this.loading = false

    this.stateId = Math.random()
  }
}

export const singleOfferStore = new OffersStatusStore({} as any, [])
export const myOffersStore = new MyOffersStore()

// const enableReactionForOffersStore = (status: ExtraOfferStatus | OfferStatus, color: string) => {
//   reaction(() => [
//     myOffersStore[status].total,
//     myOffersStore[status].itemsLeft
//   ], (value) => {
//     console.group(`%c ${status}`, `color: ${color}`)
//     console.log(`${status} TOTAL: `, value[0])
//     console.log(`${status} ITEMS LEFT: `, value[1])
//     console.log(`${status} ITEMS: `, myOffersStore[status].offers.length)
//     console.log(`${status} GAP: `, myOffersStore[status].gap)
//     console.groupEnd()
//   })
// }
//
// enableReactionForOffersStore(OfferStatus.NOT_PUBLISHED, colorByTypeWithStatus.offer.status[OfferStatus.NOT_PUBLISHED])
// enableReactionForOffersStore(OfferStatus.PUBLISHED, colorByTypeWithStatus.offer.status[OfferStatus.PUBLISHED])
// enableReactionForOffersStore(OfferStatus.AWAIT_CONFIRM, colorByTypeWithStatus.offer.status[OfferStatus.AWAIT_CONFIRM])
// enableReactionForOffersStore(OfferStatus.CONFIRMATION, colorByTypeWithStatus.offer.status[OfferStatus.CONFIRMATION])
