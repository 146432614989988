import { observer } from 'mobx-react-lite'
import { ReactNode } from 'react'
import { FreeFocusInside } from 'react-focus-lock'
import { StripeForm } from '@/components/modals/billing/StripeForm/StripeForm'
import { billingStore } from '@/store/billing/billing.store'

interface Props {
  children: ReactNode
}

export const StripeProvider = observer(({
  children,
}: Props) => (
  <>
    {children}

    <StripeForm
      open={!!billingStore.stripePaymentIntentKey}
      setOpen={() => billingStore.resetStripe()}
    />
  </>
))
